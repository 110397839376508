import React from 'react';
import {useTranslation} from 'react-i18next';
import mime from 'mime';

import {Media, Wrapper} from 'components';
import UserMessage, {
  MessageDocument,
  MessageText
} from '../../components/user-message/user-message';
import {useChatMessageContext} from '../chat-message-context';
import {downloadFile} from 'common/actions';

const File = () => {
  const {message} = useChatMessageContext();
  const {t} = useTranslation();

  const handleClick = () => {
    downloadFile(message.file_url, '');
  };

  const fileName = message.file_url.split('/').at(-1);
  const type = mime.getType(message.file_url);
  const isMedia = type?.startsWith('image') || type?.startsWith('video');

  return (
    <UserMessage>
      {type ? (
        isMedia ? (
          <Wrapper direction="column" gap={8}>
            <Media
              src={message.file_url}
              type={type?.startsWith('image') ? 'img' : 'video'}
              controls
            />

            {message.content && <MessageText>{message.content}</MessageText>}
          </Wrapper>
        ) : (
          <MessageDocument
            caption={message.content}
            fileName={fileName}
            onClick={handleClick}
          />
        )
      ) : (
        <MessageText isLight>
          {t`The message is not supported on this version.`}
        </MessageText>
      )}
    </UserMessage>
  );
};

export default File;
