import React from 'react';
import {useTranslation} from 'react-i18next';

import {Confirm} from 'components';
import {useChatContext} from '../../../chat-context';
import {useDeleteDialog} from 'pages/workspace/messenger/dispatch/hooks';
import * as AppEnv from 'app-env';

interface DeleteDialog {
  isActive: boolean;
  setIsActive: AppEnv.SetState<boolean>;
}

const DeleteDialog = ({isActive, setIsActive}: DeleteDialog) => {
  const {dialog} = useChatContext();

  const {t} = useTranslation();
  const deleteDialog = useDeleteDialog(dialog, setIsActive);

  const handleClick = (res: boolean) =>
    res ? deleteDialog() : setIsActive(false);

  const title =
    dialog.version == 'telegram'
      ? t`Are you sure you want to delete this chat?`
      : t`Are you sure you want to clear this chat?`;

  return <Confirm title={title} isActive={isActive} onClick={handleClick} />;
};

export default DeleteDialog;
