import {useChatContext} from '../../chat-context';
import {useDispatchContext} from 'pages/workspace/messenger/dispatch/dispatch-context';
import {useRequest} from 'common/hooks';
import {getDialogId} from 'common/actions';
import * as FooterEnv from '../footer-env';

const useSendText = () => {
  const {instance} = useDispatchContext();
  const {dialog, quotedMsg} = useChatContext();

  const {postSendMessage} = useRequest();

  const sendText = async (values: FooterEnv.Values) => {
    const chatId = getDialogId(dialog, true);
    const username = dialog.version == 'telegram' ? dialog.username : undefined;
    const id = username ? {username} : {chatId: chatId || undefined};

    const res = await postSendMessage(instance.chat_key, values.text, {
      ...id,
      quotedMsgId: quotedMsg?.message.id
    });

    if (!res) return false;

    const isAuthorId = 'author_id' in res;
    const isMessageId = 'message_id' in res;
    const isSent = 'sent' in res && res.sent;

    return isAuthorId || isMessageId || isSent;
  };

  return sendText;
};

export default useSendText;
