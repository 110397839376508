import React from 'react';

import {DocIcon, Media, Text, Wrapper} from 'components';
import {useDispatchContext} from 'pages/workspace/messenger/dispatch/dispatch-context';
import {useConvertBytes} from 'common/hooks';
import {getSizeLimit} from 'common/actions';
import * as AppEnv from 'app-env';

export interface Preview {
  file?: File;
  isValid: boolean;
  src: string;
}

interface PreviewProps {
  attach: AppEnv.Attach;
}

const Preview = ({attach}: PreviewProps) => {
  const {instance} = useDispatchContext();

  const convertBytes = useConvertBytes();

  const fileName = attach.file?.name || attach.title;

  const limit = attach.file ? getSizeLimit(instance, attach.file) : undefined;
  const convertedLimit = limit ? convertBytes(limit) : undefined;

  const convertedSize = attach.file
    ? convertBytes(attach.file.size)
    : undefined;

  const isMedia =
    attach.type.includes('image') || attach.type.includes('video');

  const sizeLimit = attach.file ? (
    <Text>
      <span style={{color: attach.isValid ? '#06c755' : '#ff334b'}}>
        {convertedSize}
      </span>

      {` / ${convertedLimit}`}
    </Text>
  ) : undefined;

  return (
    <>
      {isMedia ? (
        <Wrapper direction="column" gap={8}>
          <Media
            height={540}
            src={attach.src}
            type={attach.type.includes('video') ? 'video' : undefined}
            width={720}
            isAutoPlay
          />
          {sizeLimit}
        </Wrapper>
      ) : (
        <Wrapper alignItems="center" gap={12} style={{width: '100%'}} noWrap>
          <DocIcon fileName={fileName} />

          <Wrapper direction="column" gap={2} style={{minWidth: 0}}>
            <Text fontWeight={600} size={16} isNoWrap>
              {fileName}
            </Text>

            {sizeLimit}
          </Wrapper>
        </Wrapper>
      )}
    </>
  );
};

export default Preview;
