import React from 'react';
import {useTranslation} from 'react-i18next';

import {
  Button,
  Popup,
  PopupFooter,
  PopupWrapper,
  Title,
  Wrapper
} from 'components';
import {Stage} from '../tariff-list-env';
import * as AppEnv from 'app-env';

import InstanceId from './instance-id/instance-id';
import Total from './total/total';
import {useNewStage} from './hooks';

interface ConnectionSelect {
  instanceId: number[];
  setInstanceId: AppEnv.SetState<number[]>;
  setStage: AppEnv.SetState<Stage | undefined>;
  stage: Stage | undefined;
}

const ConnectionSelect = ({
  instanceId,
  setInstanceId,
  setStage,
  stage
}: ConnectionSelect) => {
  const {t} = useTranslation();
  const newStage = useNewStage();

  return (
    <Popup
      width={408}
      isActive={stage == 'connection-select'}
      onClick={() => setStage(undefined)}
    >
      <PopupWrapper>
        <Title.H3 style={{marginBottom: 16}}>{t`Connections to pay`}</Title.H3>
        <InstanceId instanceId={instanceId} setInstanceId={setInstanceId} />
      </PopupWrapper>

      <PopupFooter>
        <Wrapper direction="column" gap={16}>
          <Total />

          <Wrapper gap={12}>
            <Button
              isDisabled={instanceId.length < 1}
              onClick={() => setStage(newStage)}
            >
              {t`Continue`}
            </Button>

            <Button color="fade" onClick={() => setStage(undefined)}>
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Wrapper>
      </PopupFooter>
    </Popup>
  );
};

export default ConnectionSelect;
