import * as AppEnv from 'app-env';

const getIsDialogMuted = (dialog: AppEnv.Dialog) => {
  if (dialog.version == 'telegram') {
    const {notificationSettings} = dialog;

    if (notificationSettings.muteFor > 0) return true;
    else if (notificationSettings.soundId == '0') return true;
  } else if (dialog.version == 'whatcrm') return dialog.muteExpiration > 0;

  return false;
};

const getNotificationPermission = async () => {
  if (!('Notification' in window)) return null;

  if (Notification.permission == 'granted') return Notification.permission;
  return Notification.requestPermission();
};

export {getIsDialogMuted, getNotificationPermission};
