import React from 'react';
import {formatPhoneNumber} from 'whatcrm-core';
import update from 'immutability-helper';

import {Checkbox, Wrapper} from 'components';
import {useGetInstanceLabel} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

interface InstanceId {
  instanceId: number[];
  setInstanceId: AppEnv.SetState<number[]>;
}

const InstanceId = ({instanceId, setInstanceId}: InstanceId) => {
  const {client} = useWorkspaceContext();
  const getInstanceLabel = useGetInstanceLabel();

  const handleChange = (value: boolean, instance: AppEnv.Instance) =>
    setInstanceId(prevValue => {
      if (value) return update(prevValue, {$push: [instance.id]});

      const index = prevValue.indexOf(instance.id);
      if (index < 0) return prevValue;

      return update(prevValue, {$splice: [[index, 1]]});
    });

  const {instances} = client;

  return (
    <Wrapper direction="column" gap={8}>
      {Object.values(instances).map((instance, i) => (
        <Checkbox
          comment={
            instance.phone ? formatPhoneNumber(instance.phone) : undefined
          }
          key={instance.id}
          label={getInstanceLabel(instance)}
          name={`instance_id-${i}`}
          value={instanceId.includes(instance.id)}
          onChange={value => handleChange(value, instance)}
        />
      ))}
    </Wrapper>
  );
};

export default InstanceId;
