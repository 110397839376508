import update from 'immutability-helper';

import {useWebSocketProviderContext} from '../../web-socket-context';
import {getDialogId} from 'common/actions';
import * as AppEnv from 'app-env';

import useHandleMessages from './use-handle-messages';
import * as WhatcrmWsEnv from './whatcrm-ws-env';

const useHandleAcks = () => {
  const {dialogs, setDialogs} = useWebSocketProviderContext();
  const handleMessages = useHandleMessages();

  const handleTelegramMessage = (
    instance: AppEnv.Instance,
    dialog: AppEnv.TelegramDialog,
    dialogIndex: number,
    ack: WhatcrmWsEnv.Ack
  ) => {
    const {message} = ack;
    if (!message) return;

    const messageIndex = dialog.messages?.findIndex(
      item => item.id == ack.oldMessageId
    );

    if (messageIndex == undefined || messageIndex < 0) {
      handleMessages(instance, [{...message, oldId: ack.oldMessageId}]);
      return;
    }

    setDialogs(prevValue =>
      update(prevValue, {
        [instance.id]: {
          [dialogIndex]: {
            lastMessage: {$set: message},
            messages: {[messageIndex]: {$set: message}}
          }
        }
      })
    );
  };

  const handleTelegramAck = (
    instance: AppEnv.Instance,
    dialogIndex: number,
    ack: WhatcrmWsEnv.Ack
  ) => {
    const {lastReadOutboxMessageId} = ack;
    if (!lastReadOutboxMessageId) return;

    setDialogs(prevValue =>
      update(prevValue, {
        [instance.id]: {
          [dialogIndex]: {
            lastReadOutboxMessageId: {$set: lastReadOutboxMessageId}
          }
        }
      })
    );
  };

  const updateWhatsAppLastMessageAck = (
    instance: AppEnv.Instance,
    dialogIndex: number,
    ack: WhatcrmWsEnv.Ack
  ) =>
    setDialogs(prevValue =>
      update(prevValue, {
        [instance.id]: {
          [dialogIndex]: {lastMessage: {ack: {$set: ack.ack}}}
        }
      })
    );

  const handleWhatsAppAck = (
    instance: AppEnv.Instance,
    dialog: AppEnv.WhatsAppDialog,
    dialogIndex: number,
    ack: WhatcrmWsEnv.Ack
  ) => {
    if (dialog.lastMessage?.id._serialized == ack.id)
      updateWhatsAppLastMessageAck(instance, dialogIndex, ack);

    const messageIndex = dialog.messages?.findIndex(item => item.id == ack.id);
    if (messageIndex == undefined || messageIndex < 0) return;

    setDialogs(prevValue =>
      update(prevValue, {
        [instance.id]: {
          [dialogIndex]: {messages: {[messageIndex]: {ack: {$set: ack.ack}}}}
        }
      })
    );
  };

  const handleAcks = (instance: AppEnv.Instance, acks: WhatcrmWsEnv.Ack[]) => {
    const [ack] = acks;
    if (!ack) return;

    const dialogList = dialogs[instance.id];
    if (!dialogList) return;

    const dialogIndex = dialogList.findIndex(item => {
      const dialogId = getDialogId(item, true);
      return dialogId == ack.chatId || dialogId == ack.message?.chatId;
    });

    if (dialogIndex == undefined || dialogIndex < 0) return;
    const dialog = dialogList[dialogIndex];

    if (dialog.version == 'telegram') {
      if (ack.lastReadOutboxMessageId) {
        handleTelegramAck(instance, dialogIndex, ack);
      } else if (ack.oldMessageId) {
        handleTelegramMessage(instance, dialog, dialogIndex, ack);
      }
    } else if (dialog.version == 'whatcrm') {
      handleWhatsAppAck(instance, dialog, dialogIndex, ack);
    }
  };

  return handleAcks;
};

export default useHandleAcks;
