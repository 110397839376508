import React from 'react';

import {MessageText} from '../user-message';
import * as AppEnv from 'app-env';

interface CtwaContext {
  message: AppEnv.WhatsAppMessage;
}

const CtwaContext = ({message}: CtwaContext) => {
  const {ctwaContext} = message._data;

  return (
    <>
      {ctwaContext?.sourceUrl && (
        <MessageText>
          <a
            className="message__link"
            href={ctwaContext.sourceUrl}
            rel="noreferrer"
            target="_blank"
          >
            {ctwaContext.sourceUrl}
          </a>
        </MessageText>
      )}
    </>
  );
};

export default CtwaContext;
