import {useChatContext} from '../../chat-context';
import {useConvert, useRequest} from 'common/hooks';
import {useDispatchContext} from 'pages/workspace/messenger/dispatch/dispatch-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import {getDialogId} from 'common/actions';
import * as AppEnv from 'app-env';
import * as FooterEnv from '../footer-env';

const useSendAttachs = () => {
  const {client} = useWorkspaceContext();
  const {instance} = useDispatchContext();
  const {dialog, quotedMsg} = useChatContext();

  const {postFilesLoadData, postSendFile} = useRequest();
  const convert = useConvert();

  const sendFile = (values: FooterEnv.Values, src: string, index: number) => {
    const caption = !index ? values.text : undefined;
    const chatId = getDialogId(dialog, true);
    const username = dialog.version == 'telegram' ? dialog.username : undefined;
    const id = username ? {username} : {chatId: chatId || undefined};

    postSendFile(instance.chat_key, src, {
      ...id,
      caption,
      quotedMsgId: quotedMsg?.message.id
    });
  };

  const sendAttach = async (
    values: FooterEnv.Values,
    attach: AppEnv.Attach,
    index: number
  ) => {
    const {integration} = client;

    let src = attach.file ? await postFilesLoadData(attach.file) : attach.src;
    if (!src) return;

    if (attach.type.startsWith('video/') && dialog.version == 'whatcrm')
      src = await convert(src, 'mp4', integration.domain).then(res =>
        res ? res.url : src
      );

    if (!src) return;

    return sendFile(values, src, index);
  };

  const sendAttachs = async (values: FooterEnv.Values) => {
    const {attachs} = values;

    for (let i = 0; i < attachs.length; i++) {
      await sendAttach(values, attachs[i], i);
    }

    return true;
  };

  return sendAttachs;
};

export default useSendAttachs;
