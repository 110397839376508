import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Button, Icons, LangSelect, Select, Text, Wrapper} from 'components';
import {useAppContext} from 'app-context';
import {useInstanceOptions} from 'common/hooks';
import {useWorkspaceContext} from '../workspace-context';
import * as AppEnv from 'app-env';

interface Header {
  isSidebarActive: boolean;
  setIsSidebarActive: AppEnv.SetState<boolean>;
}

const Header = ({isSidebarActive, setIsSidebarActive}: Header) => {
  const {isIframe} = useAppContext();
  const {activeInstances, client} = useWorkspaceContext();

  const {t} = useTranslation();

  const instanceOptions = useInstanceOptions(activeInstances);
  const location = useLocation();
  const navigate = useNavigate();

  const match = location.pathname.match(/\/messenger\/(\d+)/);
  const [, instanceId]: Array<string | undefined> = match || [];

  const handleChange = (value: number) => {
    if (value == parseInt(instanceId)) return;

    setIsSidebarActive(false);
    navigate(`/messenger/${value}`);
  };

  const isManagerNamed = client.manager.fullname != client.manager.email;

  return (
    <header className="header">
      <Wrapper alignItems="center" justifyContent="space-between" noWrap>
        <div className="header__wrapper">
          <Wrapper alignItems="center" gap={8}>
            <Button
              color="transparent"
              state={isSidebarActive ? 'active' : undefined}
              onClick={() => setIsSidebarActive(prevValue => !prevValue)}
            >
              <Icons.GearSettings />
            </Button>

            <div className="header__manager">
              <Text size={!isManagerNamed ? 16 : undefined}>
                {client.manager.fullname}
              </Text>

              {isManagerNamed && <Text size={12}>{client.manager.email}</Text>}
            </div>
          </Wrapper>

          {instanceOptions.length > 0 && (
            <Select
              options={instanceOptions}
              placeholder={t`Connection`}
              value={parseInt(instanceId)}
              isAlt
              onChange={handleChange}
            />
          )}

          {!isIframe && <LangSelect />}
        </div>

        <div className="header__logo" />
      </Wrapper>
    </header>
  );
};

export default Header;
