import React from 'react';

import UserMessage, {
  MessageText
} from '../../components/user-message/user-message';
import {Radio} from 'components';
import {useWhatsAppMessageContext} from '../whatsapp-message-context';

const PollCreation = () => {
  const {message} = useWhatsAppMessageContext();

  const options =
    message.pollOptions?.map(item => ({label: item.name, value: ''})) || [];

  return (
    <UserMessage>
      <MessageText style={{marginBottom: 12}} isBold>
        {message.body}
      </MessageText>

      <Radio options={options} value="-1" isDisabled onChange={() => {}} />
    </UserMessage>
  );
};

export default PollCreation;
