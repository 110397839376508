import React, {forwardRef} from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';

interface Textarea {
  name: string;
  onChange: (arg: string) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  placeholder: string;
  value: string;
}

const Textarea = forwardRef<HTMLTextAreaElement, Textarea>(
  ({name, placeholder, value, onChange, onKeyDown}, ref) => (
    <ReactTextareaAutosize
      ref={ref}
      className="textarea"
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={e => onChange(e.target.value)}
      onKeyDown={onKeyDown}
    />
  )
);

Textarea.displayName = 'Textarea';
export default Textarea;
