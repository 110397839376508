import React from 'react';

import UserMessage, {
  MessageLocation
} from '../../components/user-message/user-message';
import {useTelegramMessageContext} from '../telegram-message-context';

const Location = () => {
  const {message} = useTelegramMessageContext();

  return (
    <UserMessage>
      <MessageLocation
        lat={message.content.location?.latitude}
        lng={message.content.location?.longitude}
      />
    </UserMessage>
  );
};

export default Location;
