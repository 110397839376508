import {getMessageTimestamp} from 'common/actions';
import * as AppEnv from 'app-env';

const sortTimestampMessages = (a: AppEnv.Message, b: AppEnv.Message) => {
  const ats = getMessageTimestamp(a);
  const bts = getMessageTimestamp(b);

  return (ats || 0) - (bts || 0);
};

export default sortTimestampMessages;
