import React from 'react';
import cn from 'classnames';

import {Text} from 'components';
import {useDispatchContext} from 'pages/workspace/messenger/dispatch/dispatch-context';
import {useChatContext} from 'pages/workspace/messenger/dispatch/chat-provider/chat/chat-context';
import {useMessageContext} from '../../../../message-context';
import {useRequest, useScrollClassName} from 'common/hooks';
import {getDialogId} from 'common/actions';
import * as AppEnv from 'app-env';

interface AvailableReactions {
  setIsContextMenuActive: AppEnv.SetState<boolean>;
}

const AvailableReactions = ({setIsContextMenuActive}: AvailableReactions) => {
  const {instance} = useDispatchContext();
  const {dialog} = useChatContext();
  const {message} = useMessageContext();

  const {postReaction} = useRequest();

  const handleClick = (emoji: string) => {
    setIsContextMenuActive(false);

    const dialogId = getDialogId(dialog, true);
    if (!dialogId) return;

    postReaction(instance.chat_key, dialogId, message.id, emoji);
  };

  const availableReactions = [
    '❤',
    '❤‍🔥',
    '🎉',
    '👌',
    '👍',
    '👎',
    '👏',
    '🔥',
    '🤔',
    '🤩',
    '🤬',
    '🤮',
    '🤯',
    '🥰',
    '😁',
    '😢',
    '😱'
  ];

  if (dialog.version == 'whatcrm') availableReactions.push('🆗');
  const scrollClassName = useScrollClassName(true);

  return (
    <div
      className={cn('available-reactions', {
        [scrollClassName]: scrollClassName
      })}
    >
      {availableReactions.map((item, i) => (
        <div
          className="available-reactions__item"
          key={i}
          onClick={() => handleClick(item)}
        >
          <Text size={24}>{item}</Text>
        </div>
      ))}
    </div>
  );
};

export default AvailableReactions;
