import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface AuthContext {
  setClient: AppEnv.SetState<AppEnv.WebAuth | null | undefined>;
}

const AuthContext = React.createContext<AuthContext>({} as AuthContext);

export const useAuthContext = () => useContext(AuthContext);
export default AuthContext;
