import React from 'react';

import {Page, Text} from 'components';
import {useScript} from '../hooks';

const Window = () => {
  const script = useScript();

  return (
    <Page>
      <div className="widget__window">
        <div className="widget__controls">
          <div className="widget__controls-item" />
          <div className="widget__controls-item" />
          <div className="widget__controls-item" />
        </div>

        <div className="widget__browser">
          <div className="widget__bar">
            <div className="widget__address-bar">
              <Text color="black" size={12}>{`https://`}</Text>
            </div>
          </div>

          <iframe className="widget__iframe" srcDoc={`<div></div>${script}`} />
        </div>
      </div>
    </Page>
  );
};

export default Window;
