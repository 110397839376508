import React from 'react';
import {useCopyToClipboard} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {
  Button,
  Popup,
  PopupFooter,
  PopupWrapper,
  Text,
  Title,
  Wrapper
} from 'components';
import {useAppContext} from 'app-context';
import {useScript} from 'pages/workspace/widget/hooks';
import * as AppEnv from 'app-env';

interface Code {
  isCodeActive: boolean;
  setIsCodeActive: AppEnv.SetState<boolean>;
}

const Code = ({isCodeActive, setIsCodeActive}: Code) => {
  const {setNotification} = useAppContext();

  const {t} = useTranslation();
  const copyToClipboard = useCopyToClipboard();
  const script = useScript();

  const handleClick = async () => {
    const res = await copyToClipboard(script);
    setNotification({title: res.message});
  };

  return (
    <Popup
      width={408}
      isActive={isCodeActive}
      onClick={() => setIsCodeActive(false)}
    >
      <PopupWrapper>
        <Title.H3 style={{marginBottom: 8}}>
          {t`Connect the widget to a website`}
        </Title.H3>

        <Text style={{marginBottom: 16}}>
          {`Copy the following element and paste it into the HTML code of your website before closing`}
          {` `}
          <span className="monospace">{`</body>`}</span>
          {`.`}
        </Text>

        <Text className="ide monospace" isNoWrap>
          {script}
        </Text>
      </PopupWrapper>

      <PopupFooter>
        <Wrapper gap={12}>
          <Button onClick={handleClick}>{t`Copy`}</Button>

          <Button color="fade" onClick={() => setIsCodeActive(false)}>
            {t`Cancel`}
          </Button>
        </Wrapper>
      </PopupFooter>
    </Popup>
  );
};

export default Code;
