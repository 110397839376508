import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useScrollClassName} from 'whatcrm-core';
import cn from 'classnames';

import * as AppEnv from 'app-env';

import Header from './header/header';
import Menu from './menu/menu';
import Version from './version/version';

interface Sidebar {
  dialogs: AppEnv.Dialogs | undefined;
  isSidebarActive: boolean;
  setIsSidebarActive: AppEnv.SetState<boolean>;
}

const Sidebar = ({dialogs, isSidebarActive, setIsSidebarActive}: Sidebar) => {
  const [messengerReferer, setMessengerReferer] = useState<string>();

  const location = useLocation();
  const scrollClassName = useScrollClassName();

  const isMessenger = !!dialogs && location.pathname.startsWith('/messenger/');

  return (
    <div
      className={cn('sidebar', {
        sidebar_active: isSidebarActive,
        sidebar_messenger: isMessenger
      })}
    >
      <Header
        messengerReferer={messengerReferer}
        setIsSidebarActive={setIsSidebarActive}
      />

      <div
        className={cn('sidebar__body', {[scrollClassName]: scrollClassName})}
      >
        <Menu
          setIsSidebarActive={setIsSidebarActive}
          setMessengerReferer={setMessengerReferer}
        />

        <Version />
      </div>
    </div>
  );
};

export default Sidebar;
