import {useChatContext} from '../../chat-context';
import {useDispatchContext} from 'pages/workspace/messenger/dispatch/dispatch-context';
import {useRequest} from 'common/hooks';
import {getDialogId} from 'common/actions';
import * as FooterEnv from '../footer-env';

const useSendPoll = () => {
  const {instance} = useDispatchContext();
  const {dialog, quotedMsg} = useChatContext();

  const {postSendMessage} = useRequest();

  const sendPoll = async (values: FooterEnv.Values) => {
    const {allowMultipleAnswers, buttons} = values;

    const chatId = getDialogId(dialog, true);
    const username = dialog.version == 'telegram' ? dialog.username : undefined;
    const id = username ? {username} : {chatId: chatId || undefined};

    const res = await postSendMessage(instance.chat_key, values.body, {
      ...id,
      allowMultipleAnswers,
      buttons,
      quotedMsgId: quotedMsg?.message.id
    });

    if (!res) return false;

    const isSent = 'sent' in res && res.sent;
    const isAuthorId = 'author_id' in res;

    return isSent || isAuthorId;
  };

  return sendPoll;
};

export default useSendPoll;
