import React, {forwardRef, useState, useEffect} from 'react';
import FuncPopup, {FuncPopupProps} from './func-popup/func-popup';

const Popup = forwardRef<HTMLDivElement, FuncPopupProps>((props, ref) => {
  const [isShown, setIsShown] = useState(false);
  const {isActive} = props;

  useEffect(() => {
    setTimeout(() => setIsShown(isActive), isActive ? undefined : 150);
  }, [isActive]);

  return isShown ? <FuncPopup {...props} ref={ref} /> : <></>;
});

Popup.displayName = 'Popup';

export {default as PopupFooter} from './popup-footer/popup-footer';
export {default as PopupWrapper} from './popup-wrapper/popup-wrapper';
export default Popup;
