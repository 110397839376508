import {fetchFile} from '@ffmpeg/util';
import {FFmpeg} from '@ffmpeg/ffmpeg';
import mime from 'mime';

import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {getDialogId} from 'common/actions';
import * as AppEnv from 'app-env';

interface Uniq {
  dialog: AppEnv.Dialog;
  instance: AppEnv.Instance;
}

const useUploadVoice = (version: AppEnv.InstanceVersion) => {
  const {isCanPlayOgg} = useAppContext();

  const {postFilesLoadData} = useRequest();
  const ffmpeg = new FFmpeg();

  const getMp3Blob = async (blob: Blob) => {
    await ffmpeg.load();

    const ext = mime.getExtension(blob.type);
    const input = `input.${ext}`;
    const output = 'output.mp3';

    await ffmpeg.writeFile(input, await fetchFile(blob));
    await ffmpeg.exec(['-i', input, output]);

    const fileData = await ffmpeg.readFile(output);
    const mp3Blob = new Blob([fileData], {type: 'audio/mp3'});

    return mp3Blob;
  };

  const getName = (uniq: Uniq | undefined) => {
    const ext = isCanPlayOgg ? 'oga' : 'mp3';
    if (!uniq) return `voice_message.${ext}`;

    const {dialog, instance} = uniq;
    const name = `${instance.phone}_${getDialogId(dialog)}_voice_message.${ext}`;

    return name;
  };

  const uploadVoice = async (blob: Blob, uniq?: Uniq) => {
    const isBlob = isCanPlayOgg && version != 'whatcrm';
    const body = isBlob ? blob : await getMp3Blob(blob);

    const name = getName(uniq);
    const src = await postFilesLoadData(body, name);

    return src;
  };

  return uploadVoice;
};

export default useUploadVoice;
