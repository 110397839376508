import React from 'react';

import UserMessage, {
  MessageText
} from '../../components/user-message/user-message';
import {useAvitoMessageContext} from '../avito-message-context';

const Link = () => {
  const {message} = useAvitoMessageContext();
  const {link} = message.content;

  return (
    <UserMessage>
      <MessageText>
        <a
          className="message__link"
          href={link?.url}
          rel="noreferrer"
          target="_blank"
        >
          {link?.text}
        </a>
      </MessageText>
    </UserMessage>
  );
};

export default Link;
