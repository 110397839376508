import Fuse from 'fuse.js';

import {useGetFolderDialogList} from 'common/hooks';
import {useDispatchContext} from 'pages/workspace/messenger/dispatch/dispatch-context';
import {getIsNewMessageAvailable, sortDialogs} from 'common/actions';

const useFusedDialogList = (search: string) => {
  const {dialogs, instance} = useDispatchContext();
  const getFolderDialogList = useGetFolderDialogList();

  const dialogList = getFolderDialogList(dialogs[instance.id] || [], null);

  const fuse = new Fuse(dialogList, {
    keys: ['id.user', 'id', 'name'],
    threshold: 0.25
  });

  let fusedDialogList = dialogList.filter(item =>
    getIsNewMessageAvailable(instance, item)
  );

  fusedDialogList = search
    ? fuse.search(search).map(item => item.item)
    : fusedDialogList;

  fusedDialogList.sort(sortDialogs);

  return fusedDialogList;
};

export {useFusedDialogList};
