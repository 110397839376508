import update from 'immutability-helper';

import {useChatContext} from '../../../../chat-context';
import {useDispatchContext} from 'pages/workspace/messenger/dispatch/dispatch-context';
import {getDialogId} from 'common/actions';
import * as AppEnv from 'app-env';

import useGetMyProfileImage from './use-get-my-profile-image/use-get-my-profile-image';
import {sortTelegramMessages, sortTimestampMessages} from './actions';

const useUpdateMessageList = () => {
  const {dialogs, instance, setDialogs} = useDispatchContext();
  const {dialog} = useChatContext();

  const getMyProfileImage = useGetMyProfileImage();

  const updateMessageList = (messages: AppEnv.Message[]) => {
    const [message] = messages;

    const index = dialogs[instance.id]?.findIndex(
      item => getDialogId(item) == getDialogId(dialog)
    );

    if (index == undefined || index < 0) return;

    const chatName =
      message && 'chatName' in message ? message.chatName : undefined;

    const sortedMessages = dialog.messages
      ? [...messages, ...dialog.messages]
      : messages;

    if (dialog.version == 'avito' || dialog.version == 'whatcrm')
      sortedMessages?.sort(sortTimestampMessages);
    else if (dialog.version == 'telegram')
      sortedMessages?.sort(sortTelegramMessages);

    setDialogs(prevValue =>
      update(prevValue, {
        [instance.id]: {
          [index]: {
            messages: {$set: sortedMessages as any}, // eslint-disable-line
            name: {$apply: target => chatName || target}
          }
        }
      })
    );

    if (!messages.length) return;

    const isOutgoings = !![...messages].find(item => {
      if ('direction' in item) return item.direction == 'out';
      else if ('isOutgoing' in item) return item.isOutgoing;
      else if ('fromMe' in item) return item.fromMe;
    });

    if (isOutgoings) getMyProfileImage();
  };

  return updateMessageList;
};

export default useUpdateMessageList;
