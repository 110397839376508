import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Button,
  Checkbox,
  Popup,
  PopupFooter,
  PopupWrapper,
  Text,
  Title,
  Wrapper
} from 'components';
import {useTariffListContext} from '../tariff-list-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import {PaymentGateway, Stage} from '../tariff-list-env';
import * as AppEnv from 'app-env';

import Label from './label/label';
import {getNextPaymentDate} from './actions';

interface LicenseAgreement {
  instanceId: number[];
  isSubscribed: boolean;
  location: string | undefined;
  paymentGateway: PaymentGateway;
  setStage: AppEnv.SetState<Stage | undefined>;
  stage: Stage | undefined;
}

const LicenseAgreement = ({
  instanceId,
  isSubscribed,
  location,
  paymentGateway,
  setStage,
  stage
}: LicenseAgreement) => {
  const {client} = useWorkspaceContext();
  const {selectedTariff, total} = useTariffListContext();

  const [isChecked, setIsChecked] = useState(false);
  const {i18n, t} = useTranslation();

  const handleClick = () => {
    if (!selectedTariff) return;

    const {integration} = client;

    const urlSearchParams = new URLSearchParams({
      domain: integration.domain,
      is_subscribed: isSubscribed ? '1' : '0',
      redirect: window.location.href,
      tariff_id: selectedTariff.id.toString(),
      type: integration.type
    });

    instanceId.forEach(id =>
      urlSearchParams.append('instance_id', id.toString())
    );

    if (instanceId.length > 1)
      urlSearchParams.append('quantity', instanceId.length.toString());

    if (location) urlSearchParams.append('location', location);

    const src = `${process.env.REACT_APP_API}/payments/${paymentGateway}?${urlSearchParams}`;
    window.open(src, integration.type == 'PIPEDRIVE' ? '_blank' : '_self');
  };

  const isOneTimeWarningShown = i18n.resolvedLanguage != 'ru' && !isSubscribed;
  const nextPaymentDate = getNextPaymentDate(selectedTariff?.quantity || 30);

  const title = isSubscribed
    ? t`Confirmation of subscription start`
    : t`Confirmation of payment`;

  return (
    <Popup
      width={408}
      isActive={stage == 'license-agreement'}
      onClick={() => setStage(undefined)}
    >
      <PopupWrapper>
        <Title.H3 style={{marginBottom: 16}}>{title}</Title.H3>

        {isOneTimeWarningShown ? (
          <Text style={{marginBottom: 16}}>
            {t`For one-time payments, our platform does not provide invoices or receipts in PDF format. Thank you for your understanding.`}
          </Text>
        ) : (
          isSubscribed && (
            <>
              <Text style={{marginBottom: 8}}>
                {t`You have selected the recurring payment option. Your subscription will automatically renew once every set period specified in your rate plan, and your account will be charged accordingly.`}
              </Text>

              <Text style={{marginBottom: 8}}>
                {t`You will have access to our service for the duration of your subscription.`}
              </Text>

              <Text style={{marginBottom: 16}}>
                {t`Next payment`}
                {`: `}
                {total.toLocaleString(i18n.resolvedLanguage, {
                  currency: selectedTariff?.currency || 'RUB',
                  style: 'currency'
                })}
                {`, `}
                {nextPaymentDate}
              </Text>
            </>
          )
        )}

        <Checkbox
          label={<Label />}
          name="license-agreement"
          value={isChecked}
          onChange={setIsChecked}
        />
      </PopupWrapper>

      <PopupFooter>
        <Wrapper gap={12}>
          <Button isDisabled={!isChecked} onClick={handleClick}>
            {t`Continue`}
          </Button>

          <Button color="fade" onClick={() => setStage(undefined)}>
            {t`Cancel`}
          </Button>
        </Wrapper>
      </PopupFooter>
    </Popup>
  );
};

export default LicenseAgreement;
