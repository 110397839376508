import React from 'react';

import {Button, Wrapper, Text} from 'components';
import UserMessage, {
  MessageText
} from '../../components/user-message/user-message';
import {useWhatsAppMessageContext} from '../whatsapp-message-context';
import {getIsEmoji} from 'common/actions';

const Chat = () => {
  const {message} = useWhatsAppMessageContext();

  const text = message.body || message.caption;
  const isEmoji = getIsEmoji(text || '');

  return (
    <UserMessage>
      <Wrapper direction="column" gap={8}>
        <MessageText>
          {isEmoji ? <Text size={72}>{text}</Text> : text}
        </MessageText>

        {message.dynamicReplyButtons &&
          message.dynamicReplyButtons.length > 0 && (
            <Wrapper gap={8}>
              {message.dynamicReplyButtons.map((item, i) => (
                <Button
                  color="white"
                  key={i}
                  isDisabled
                  isSmall
                  onClick={() => {}}
                >
                  {item.buttonText.displayText}
                </Button>
              ))}
            </Wrapper>
          )}
      </Wrapper>
    </UserMessage>
  );
};

export default Chat;
