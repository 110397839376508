import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Button} from 'components';
import Code from './code/code';

const AsideFooter = () => {
  const [isCodeActive, setIsCodeActive] = useState(false);
  const {t} = useTranslation();

  return (
    <div className="aside__footer">
      <Button onClick={() => setIsCodeActive(true)}>{t`Continue`}</Button>
      <Code isCodeActive={isCodeActive} setIsCodeActive={setIsCodeActive} />
    </div>
  );
};

export default AsideFooter;
