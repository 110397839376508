import React, {useState} from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Anchor, ChipsOption, Icons, Wrapper} from 'components';
import * as AppEnv from 'app-env';

import Social from './social/social';
import SocialAdder from './social-adder/social-adder';

const Socials = () => {
  const [isSocialAdderActive, setIsSocialAdderActive] = useState(false);

  const [field] = useField<AppEnv.WidgetSocial[]>('socials');
  const {i18n, t} = useTranslation();

  const options: ChipsOption<AppEnv.WidgetSocialVersion>[] = [
    {icon: <Icons.Telegram />, label: 'Telegram', value: 'telegram'},
    {icon: <Icons.WhatsApp />, label: 'WhatsApp', value: 'whatsapp'},
    {
      icon: <Icons.Facebook />,
      label: `Facebook${i18n.resolvedLanguage == 'ru' && '*'}`,
      value: 'facebook'
    },
    {
      icon: <Icons.Instagram />,
      label: `Instagram${i18n.resolvedLanguage == 'ru' && '*'}`,
      value: 'instagram'
    },
    {icon: <Icons.LinkedIn />, label: 'LinkedIn', value: 'linkedin'},
    {
      icon: <Icons.Twitter />,
      label: `X (${t`formerly`} Twitter)`,
      value: 'twitter'
    }
  ];

  const fusedOptions = options.map(option => ({
    ...option,
    isDisabled: field.value.some(social => social.version == option.value)
  }));

  const isDisabled = !fusedOptions.some(option => !option.isDisabled);

  return (
    <div>
      {field.value.length > 0 && (
        <Wrapper direction="column" gap={8} style={{marginBottom: 16}}>
          {field.value.map((social, i) => (
            <Social index={i} key={i} social={social} />
          ))}
        </Wrapper>
      )}

      <Wrapper justifyContent="center">
        <Anchor
          size={16}
          textAlign="center"
          isDisabled={isDisabled}
          onClick={() => setIsSocialAdderActive(true)}
        >
          {t`Add a social network`}
          <Icons.Plus />
        </Anchor>
      </Wrapper>

      <SocialAdder
        options={fusedOptions}
        isSocialAdderActive={isSocialAdderActive}
        setIsSocialAdderActive={setIsSocialAdderActive}
      />
    </div>
  );
};

export default Socials;
