import React, {useState} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import * as AppEnv from 'app-env';

import Connections from './сonnections/connections';
import Header from './header/header';
import Messenger from './messenger/messenger';
import Settings from './settings/settings';
import Sidebar from './sidebar/sidebar';
import WebSocketProvider from './web-socket-provider/web-socket-provider';
import Widget from './widget/widget';
import WorkspaceContext from './workspace-context';
import {
  useActiveInstances,
  useDialogs,
  useIsInitialSidebarActive,
  useMe,
  useUpdateMessageBody
} from './hooks';

interface Workspace {
  client: AppEnv.WebAuth;
  setClient: AppEnv.SetState<AppEnv.WebAuth | null | undefined>;
}

const Workspace = ({client, setClient}: Workspace) => {
  const isInitialSidebarActive = useIsInitialSidebarActive();

  const [isSidebarActive, setIsSidebarActive] = useState(
    isInitialSidebarActive
  );

  const [tariffs, setTariffs] = useState<AppEnv.Tariffs | null>();

  const activeInstances = useActiveInstances(client);
  const [dialogs, setDialogs] = useDialogs(client, activeInstances);
  const updateMessageBody = useUpdateMessageBody(dialogs, setDialogs);
  useMe(activeInstances, setClient);

  return (
    <WorkspaceContext.Provider
      value={{
        activeInstances,
        client,
        setClient,
        setTariffs,
        tariffs,
        updateMessageBody
      }}
    >
      <div className="workspace">
        <Header
          isSidebarActive={isSidebarActive}
          setIsSidebarActive={setIsSidebarActive}
        />

        <Sidebar
          dialogs={dialogs}
          isSidebarActive={isSidebarActive}
          setIsSidebarActive={setIsSidebarActive}
        />

        <Routes>
          <Route
            path="/connections/*"
            element={<Connections setDialogs={setDialogs} />}
          />

          <Route path="/settings" element={<Settings />} />

          <Route
            path="/messenger/:instanceId?/:dialogId?"
            element={<Messenger dialogs={dialogs} setDialogs={setDialogs} />}
          />

          <Route path="/widget/*" element={<Widget />} />
          <Route path="*" element={<Navigate to="/messenger" />} />
        </Routes>
      </div>

      {dialogs && (
        <WebSocketProvider dialogs={dialogs} setDialogs={setDialogs} />
      )}
    </WorkspaceContext.Provider>
  );
};

export default Workspace;
