import {useAppContext} from 'app-context';
import {getIsDialogGroup, getIsLimited} from 'common/actions';
import * as AppEnv from 'app-env';

const useGetIsDialogAvailable = (client: AppEnv.WebAuth) => {
  const {isIframe} = useAppContext();

  const getIsDialogBlocked = (dialog: AppEnv.Dialog) =>
    !!client.blacklist.find(
      item => typeof dialog.id == 'object' && item.phone == dialog.id.user
    );

  const getIsDialogUnavailable = (
    instance: AppEnv.Instance,
    dialog: AppEnv.Dialog
  ) => {
    const isDialogBlocked = getIsDialogBlocked(dialog);
    const isDialogGroup = getIsDialogGroup(dialog);
    const isLimited = getIsLimited(instance);
    const isAvailableGroup = !isLimited || isIframe || !isDialogGroup;

    return isAvailableGroup && !isDialogBlocked;
  };

  return getIsDialogUnavailable;
};

export default useGetIsDialogAvailable;
