import React from 'react';

import {Label} from '../components';
import {Wrapper} from 'components';

import DatePay from './date-pay/date-pay';
import Id from './id/id';
import Name from './name/name';
import Phone from './phone/phone';

const InstanceBody = () => (
  <div className="instance__body">
    <Wrapper gap={12} direction="column">
      <Label />

      <Wrapper direction="column" gap={4}>
        <DatePay />
        <Name />
        <Phone />
        <Id />
      </Wrapper>
    </Wrapper>
  </div>
);

export default InstanceBody;
