import moment from 'moment';
import * as AppEnv from 'app-env';

const getIsMessageEdited = (message: AppEnv.Message) => {
  if ('sender_id' in message) return moment(message.edited_at).unix() > 0;
  else if ('senderId' in message) return message.editDate > 0;
  else if ('_data' in message)
    return (message.latestEditSenderTimestampMs || 0) > 0;

  return false;
};

export default getIsMessageEdited;
