import {useAppContext} from 'app-context';
import {useGetDialog, useRequest} from 'common/hooks';
import {processTelegramDialog} from 'common/actions';
import * as AppEnv from 'app-env';

import useGetValidPhone from './use-get-valid-phone/use-get-valid-phone';

const useCreateDialogs = (activeInstances: AppEnv.Instance[]) => {
  const {chatIdList, phoneNumberList, usernameList} = useAppContext();

  const {fetchSearchPublicChat} = useRequest();
  const getDialog = useGetDialog();
  const getValidPhone = useGetValidPhone();

  const createDialogs = async () => {
    const sample: AppEnv.Dialogs = {};

    const promises = activeInstances.map(async item => {
      const chatIdPromises = chatIdList?.map(async chatId => {
        const res = await getDialog(item, {id: chatId});

        if (!res) return;
        else if (sample[item.id]) return sample[item.id]?.push(res);

        sample[item.id] = [res];
      });

      const phoneNumberPromises = phoneNumberList?.map(async phoneNumber => {
        const phone = await getValidPhone(item, phoneNumber);
        const res = await getDialog(item, {phone});

        if (!res) return;
        else if (sample[item.id]) return sample[item.id]?.push(res);

        sample[item.id] = [res];
      });

      let usernamePromises: Promise<void>[] | undefined = undefined;

      if (item.version == 'telegram') {
        usernamePromises = usernameList?.map(async username => {
          const res = await fetchSearchPublicChat(item.chat_key, username);
          if (!res) return;

          const telegramDialog = processTelegramDialog(res);

          if (sample[item.id]) {
            sample[item.id]?.push(telegramDialog);
            return;
          }

          sample[item.id] = [telegramDialog];
        });
      }

      await Promise.all(chatIdPromises || []);
      await Promise.all(phoneNumberPromises || []);
      await Promise.all(usernamePromises || []);
    });

    await Promise.all(promises);
    return sample;
  };

  return createDialogs;
};

export default useCreateDialogs;
