import React, {useState, useEffect} from 'react';

import {useDispatchContext} from 'pages/workspace/messenger/dispatch/dispatch-context';
import {useIsVisible, useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

import {useWhatsAppMessageContext} from './whatsapp-message-context';

const useMedia = (ref: React.RefObject<HTMLElement>) => {
  const {updateMessageBody} = useWorkspaceContext();
  const {instance} = useDispatchContext();
  const {message} = useWhatsAppMessageContext();

  const [isRequested, setIsRequested] = useState(false);

  const {fetchDownloadMedia} = useRequest();
  const isVisible = useIsVisible(ref);

  const getMedia = async () => {
    setIsRequested(true);
    if (!message.mediaKey) return;

    const res = await fetchDownloadMedia(
      instance.chat_key,
      message.id,
      message.mediaKey
    );

    if (!Array.isArray(res) && res?.url) updateMessageBody(message.id, res.url);
  };

  useEffect(() => {
    if (!message.body && !isRequested && isVisible) getMedia();
  }, [isVisible]);
};

export default useMedia;
