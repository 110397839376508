import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {Button, Confirm, Icons, Wrapper} from 'components';
import {useAppContext} from 'app-context';
import {useGetInstanceLabel, useRequest} from 'common/hooks';
import {useInstanceContext} from '../instance-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

import Connect from './connect/connect';
import Editor from './editor/editor';

const InstanceMenu = () => {
  const {setNotification} = useAppContext();
  const {setClient} = useWorkspaceContext();
  const {instance} = useInstanceContext();

  const [isConfirmActive, setIsConfirmActive] = useState(false);
  const [isEditorActive, setIsEditorActive] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const {fetchChatFree} = useRequest();
  const {t} = useTranslation();
  const getInstanceLabel = useGetInstanceLabel();

  const deleteInstance = async () => {
    const res = await fetchChatFree(instance.chat_key);
    if (!res?.success) return true;

    const title = getInstanceLabel(instance);
    setNotification({title, text: t`The connection has been deleted.`});

    setClient(prevValue =>
      update(prevValue, {instances: {$unset: [instance.id]}})
    );

    return true;
  };

  const handleConfirm = (res: boolean) =>
    res ? deleteInstance() : setIsConfirmActive(false);

  return (
    <>
      <div className="instance__menu">
        <Connect isFetching={isFetching} setIsFetching={setIsFetching} />

        <Wrapper noWrap>
          <Button
            color="transparent"
            isDisabled={isFetching}
            onClick={() => setIsEditorActive(true)}
          >
            <Icons.PencilSimple size={20} />
          </Button>

          <Button
            color="transparent"
            isDisabled={isFetching}
            onClick={() => setIsConfirmActive(true)}
          >
            <Icons.TrashSimple size={20} />
          </Button>
        </Wrapper>
      </div>

      <Confirm
        title={t`Are you sure you want to delete this connection?`}
        isActive={isConfirmActive}
        onClick={handleConfirm}
      />

      <Editor
        isEditorActive={isEditorActive}
        setIsEditorActive={setIsEditorActive}
      />
    </>
  );
};

export default InstanceMenu;
