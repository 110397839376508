import {getIsInstanceActive} from 'common/actions';
import * as AppEnv from 'app-env';

const useActiveInstances = (client: AppEnv.WebAuth) => {
  const activeInstances = Object.values(client.instances).filter(instance =>
    getIsInstanceActive(client, instance)
  );

  return activeInstances;
};

export default useActiveInstances;
