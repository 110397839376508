import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {removeFromLocalStorage} from 'whatcrm-core';

import {Confirm} from 'components';
import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';

import MenuItem from './menu-item/menu-item';

interface Menu {
  setIsSidebarActive: AppEnv.SetState<boolean>;
  setMessengerReferer: AppEnv.SetState<string | undefined>;
}

const Menu = ({setIsSidebarActive, setMessengerReferer}: Menu) => {
  const {isIframe} = useAppContext();
  const [isConfirmActive, setIsConfirmActive] = useState(false);

  const location = useLocation();
  const {t} = useTranslation();

  const handleClick = () => {
    window.screen.width <= 768 ? setIsSidebarActive(false) : undefined;

    if (!location.pathname.startsWith('/messenger/')) return;
    setMessengerReferer(location.pathname);
  };

  const signOut = () => {
    removeFromLocalStorage('email');
    window.location.href = '/auth';
  };

  const handleConfirm = (res: boolean) =>
    res ? signOut() : setIsConfirmActive(false);

  return (
    <>
      <div className="sidebar__menu">
        <>
          {process.env.REACT_APP_ENV == 'dev' && (
            <>
              {!isIframe && (
                <Link to="/connections">
                  <MenuItem
                    isActive={location.pathname.startsWith('/connections')}
                    onClick={handleClick}
                  >
                    {t`Connections`}
                  </MenuItem>
                </Link>
              )}
            </>
          )}
        </>

        <Link to="/settings">
          <MenuItem
            isActive={location.pathname.startsWith('/settings')}
            onClick={handleClick}
          >
            {t`Settings`}
          </MenuItem>
        </Link>

        {process.env.REACT_APP_ENV == 'dev' && (
          <>
            {!isIframe && (
              <Link to="/widget">
                <MenuItem
                  isActive={location.pathname.startsWith('/widget')}
                  onClick={handleClick}
                >
                  {t`Web widget`}
                </MenuItem>
              </Link>
            )}
          </>
        )}

        {!isIframe && (
          <MenuItem onClick={() => setIsConfirmActive(true)}>
            {t`Sign out`}
          </MenuItem>
        )}
      </div>

      <Confirm
        title={t`Are you sure you want to sign out?`}
        isActive={isConfirmActive}
        onClick={handleConfirm}
      />
    </>
  );
};

export default Menu;
