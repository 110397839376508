import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {useAppContext} from 'app-context';
import {useDispatchContext} from 'pages/workspace/messenger/dispatch/dispatch-context';
import {useDialogContext} from '../dialog-context';
import {useRequest} from 'common/hooks';
import {getDialogId} from 'common/actions';

const useToggleRead = () => {
  const {setNotification} = useAppContext();
  const {dialogs, instance, setDialogs} = useDispatchContext();
  const {dialog} = useDialogContext();

  const {fetchChatRead, fetchReadChat, fetchUnreadChat} = useRequest();
  const {t} = useTranslation();

  const request = (value: boolean) => {
    const id = getDialogId(dialog, true);
    if (!id) return null;

    if (value) {
      if (dialog.version == 'avito')
        return fetchChatRead(instance.chat_key, instance.instanceId, dialog.id);

      return fetchReadChat(instance.chat_key, id);
    }

    return fetchUnreadChat(instance.chat_key, id);
  };

  const toggleRead = async (value: boolean) => {
    const id = getDialogId(dialog, true);
    if (!id) return throwError(value);

    await request(value);

    const index = dialogs[instance.id]?.findIndex(
      item => getDialogId(item, true) == id
    );

    if (typeof index != 'number' || index < 0) return throwError(value);

    setDialogs(prevValue =>
      update(prevValue, {
        [instance.id]: {
          [index]:
            dialog.version == 'avito'
              ? {last_message: {read: {$set: 1}}}
              : dialog.version == 'telegram'
              ? value
                ? {isMarkedAsUnread: {$set: false}, unreadCount: {$set: 0}}
                : {isMarkedAsUnread: {$set: true}}
              : dialog.version == 'whatcrm'
              ? {unreadCount: {$set: value ? 0 : -1}}
              : {}
        }
      })
    );
  };

  const throwError = (value: boolean) =>
    setNotification({
      title: value
        ? t`Failed to mark the chat as read`
        : t`Failed to mark the chat as unread`
    });

  return toggleRead;
};

export default useToggleRead;
