import {useState} from 'react';

import {useChatContext} from '../../../chat-context';
import {useDispatchContext} from 'pages/workspace/messenger/dispatch/dispatch-context';
import {useRequest} from 'common/hooks';
import {getIsMessageLimited, getMessageTimestamp} from 'common/actions';

import useUpdateMessageList from './use-update-message-list/use-update-message-list';

const useGetMessageList = () => {
  const {instance} = useDispatchContext();
  const {dialog} = useChatContext();

  const [isAllMessagesReceived, setIsAllMessagesReceived] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [offset, setOffset] = useState(dialog.messages?.length || 0);

  const {fetchMessages} = useRequest();
  const updateMessageList = useUpdateMessageList();

  const getMessageList = async () => {
    if (isAllMessagesReceived || isFetching) return;
    setIsFetching(true);

    const latestMessage = dialog.messages?.[0];
    const latestMessageId = latestMessage?.id;

    const maxTimestamp = latestMessage
      ? getMessageTimestamp(latestMessage)
      : undefined;

    const res = await fetchMessages(
      instance.chat_key,
      dialog.version == 'whatcrm' ? dialog.id._serialized : dialog.id,
      dialog.version,
      typeof latestMessageId == 'number' ? latestMessageId : undefined,
      maxTimestamp || Math.round(Date.now() / 1000),
      offset,
      dialog.version == 'telegram' && !!dialog.username
    );

    const messages = (Array.isArray(res) ? res : res?.messages)?.filter(
      item => !getIsMessageLimited(instance, item)
    );

    if (dialog.version == 'chat' || !messages?.length)
      setIsAllMessagesReceived(true);

    setOffset(prevValue => prevValue + (messages?.length || 0));
    updateMessageList(messages || []);
    setIsFetching(false);
  };

  return getMessageList;
};

export default useGetMessageList;
