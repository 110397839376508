import * as AppEnv from 'app-env';

import useHandleMessages from './use-handle-messages';
import * as WhatcrmWsEnv from './whatcrm-ws-env';

const useHandleReactions = () => {
  const handleMessages = useHandleMessages();

  const getMessage = (reactions: WhatcrmWsEnv.Reaction[]) => {
    const [reaction] = reactions;
    const {message} = reaction;

    if (message.reactions) return message;

    message.reactions = [
      {
        id: reaction.reaction,
        senders: [{senderId: reaction.senderId}]
      }
    ];

    return message;
  };

  const handleReactions = (
    instance: AppEnv.Instance,
    reactions: WhatcrmWsEnv.Reaction[]
  ) => {
    const message = getMessage(reactions);
    handleMessages(instance, [message]);
  };

  return handleReactions;
};

export default useHandleReactions;
