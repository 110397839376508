import React from 'react';

import SystemMessage from '../system-message/system-message';
import UserMessage, {MessageText} from '../user-message/user-message';
import {useMessageContext} from '../../message-context';
import {useGetMessagePreview} from 'common/hooks';

interface MessagePreview {
  isLight?: boolean;
  isSystemMessage?: boolean;
}

const MessagePreview = ({isLight, isSystemMessage}: MessagePreview) => {
  const {message} = useMessageContext();
  const getMessagePreview = useGetMessagePreview();

  const messagePreview = getMessagePreview(message);

  return (
    <>
      {isSystemMessage ? (
        <SystemMessage isAlt={'author_id' in message}>
          {messagePreview}
        </SystemMessage>
      ) : (
        <UserMessage>
          <MessageText isLight={isLight}>{messagePreview}</MessageText>
        </UserMessage>
      )}
    </>
  );
};

export default MessagePreview;
