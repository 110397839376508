import update from 'immutability-helper';

import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

const useUpdateInstanceState = () => {
  const {setClient} = useWorkspaceContext();

  const updateInstanceState = (
    instance: AppEnv.Instance,
    status: AppEnv.InstanceStatus,
    condition: AppEnv.InstanceCondition | undefined
  ) =>
    setClient(prevValue =>
      update(prevValue, {
        instances: {
          [instance.id]: {condition: {$set: condition}, status: {$set: status}}
        }
      })
    );

  return updateInstanceState;
};

export default useUpdateInstanceState;
