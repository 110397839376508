import React from 'react';
import {useTranslation} from 'react-i18next';
import * as AppEnv from 'app-env';

const useFeatureList = (
  crm: AppEnv.Crm,
  tariffPlan: AppEnv.TariffPlan,
  isEnterpriseOption: boolean
) => {
  const {i18n, t} = useTranslation();

  return [
    {
      isChecked: true,
      isLimits: true,
      name: t`Sending and receiving files`
    },
    {
      isChecked: true,
      name: t`Write first`,
      skip: crm == 'TELEGRAM' || crm == 'TELPHGRAM'
    },
    {
      isChecked: true,
      name: t`Write first from any connection by phone number or username`,
      skip: crm !== 'TELEGRAM'
    },
    {
      isChecked: true,
      name: t`Write first from any connection by phone number`,
      skip: crm !== 'TELPHGRAM'
    },
    {
      isChecked: true,
      name: t`Working with Salesbot`,
      skip: crm !== 'AMO' && crm !== 'TELEGRAM'
    },
    {
      isChecked: true,
      name: t`Working with chat bots and business Processes`,
      skip: crm !== 'B24' && crm !== 'TELPHGRAM'
    },
    {
      isChecked: true,
      name: t`Unlimited dialogs`
    },
    {
      isChecked: true,
      name: t`CRM plugin for your website`,
      skip: crm == 'MONDAY' || crm == 'TGMONDAY'
    },
    {
      isChecked: true,
      name: t`Auto messaging with files`,
      skip: crm != 'MONDAY' && crm != 'TGMONDAY'
    },
    {
      isChecked: true,
      name: t`Choosing a pipeline for a phone number`,
      skip: crm !== 'TELEGRAM'
    },
    {
      isChecked: crm == 'LK' || ['Enterprise', 'Pro'].includes(tariffPlan),
      name: 'Whatcrm Web'
    },
    {
      isChecked: ['Enterprise', 'Pro'].includes(tariffPlan),
      name: t`Working with group chats`
    },
    {
      isChecked: ['Enterprise', 'Pro'].includes(tariffPlan),
      name: t`Missed calls`,
      skip: crm == 'B24' || crm == 'TELPHGRAM'
    },
    {
      isChecked: ['Enterprise', 'Pro'].includes(tariffPlan),
      name: t`Sending and displaying reactions`
    },
    {
      isChecked: ['Enterprise', 'Pro'].includes(tariffPlan),
      name: t`Salesbot with buttons`,
      skip: true
    },
    {
      isChecked: ['Enterprise', 'Pro'].includes(tariffPlan),
      name: t`Distribution of incoming leads by strategies`,
      skip:
        crm != 'AMO' &&
        crm != 'TELEGRAM' &&
        crm != 'MONDAY' &&
        crm != 'TGMONDAY'
    },
    {
      isChecked: ['Enterprise', 'Pro'].includes(tariffPlan),
      name: t`Access to Whatcrm API`,
      skip: crm !== 'LK'
    },
    {
      isChecked: ['Enterprise'].includes(tariffPlan),
      isShown: true,
      name: t`Sending voice messages in open line chat`,
      skip: crm != 'B24'
    },
    {
      isChecked: ['Enterprise'].includes(tariffPlan),
      isShown: true,
      name: 'Поддержка по телефону',
      skip: i18n.resolvedLanguage != 'ru' || !isEnterpriseOption,
      tooltip: (
        <>
          {'Понедельник–Пятница'}
          <br />
          {'9:00–18:00'}
        </>
      )
    },
    {
      isChecked: ['Enterprise'].includes(tariffPlan),
      name: t`Private server`,
      skip: !isEnterpriseOption,
      tooltip: t`A server whose resources are only under your control is allocated on request.`
    },
    {
      isChecked: ['Enterprise'].includes(tariffPlan),
      name: t`Access to the history of all files`,
      skip: !isEnterpriseOption
    },
    {
      isChecked: ['Enterprise'].includes(tariffPlan),
      name: t`Connecting your own S3 storage`,
      skip: !isEnterpriseOption,
      tooltip: t`Your own S3 storage can be used to store incoming and outgoing files.`
    }
  ].filter(item => !item.skip);
};

export {useFeatureList};
