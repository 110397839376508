import React from 'react';
import {Link} from 'react-router-dom';
import cn from 'classnames';

export interface NavItemParams {
  label: string;
  value: string;
}

interface NavItem {
  isActive: boolean;
  navItem: NavItemParams;
}

const NavItem = ({isActive, navItem}: NavItem) => (
  <Link
    className={cn('nav__item', {nav__item_active: isActive})}
    to={navItem.value}
  >
    {navItem.label}
  </Link>
);

export default NavItem;
