import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {useAppContext} from 'app-context';
import {useConnectionsContext} from 'pages/workspace/сonnections/connections-context';
import {useConnectionsMainContext} from 'pages/workspace/сonnections/connections-main/connections-main-context';
import {useGetInstanceLabel, useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import useGetAvailableDialogList from 'pages/workspace/hooks/use-dialogs/use-get-dialogs/use-get-available-dialog-list/use-get-available-dialog-list';
import {getIsInstancePaid} from 'common/actions';
import {Event} from '../use-events-ws-env';
import * as AppEnv from 'app-env';

const useHandleConnected = () => {
  const {setNotification} = useAppContext();
  const {client, setClient} = useWorkspaceContext();
  const {setDialogs} = useConnectionsContext();
  const {setConnectorId} = useConnectionsMainContext();

  const {fetchDialogs} = useRequest();
  const {t} = useTranslation();
  const getAvailableDialogList = useGetAvailableDialogList(client);
  const getInstanceLabel = useGetInstanceLabel();

  const handleConnected = async (instance: AppEnv.Instance, event: Event) => {
    const {info} = event;
    const {me} = info || {};
    const id = info?.id || me?._serialized;

    setConnectorId(undefined);

    setClient(prevValue =>
      update(prevValue, {
        instances: {
          [instance.id]: {
            condition: {$set: undefined},
            me: {$set: {id: id || 0}},
            name: {$set: info?.pushname || ''},
            phone: {$set: info?.phoneNumber || me?.user || ''},
            status: {$set: 'CONNECTED'}
          }
        }
      })
    );

    const isInstancePaid = getIsInstancePaid(client, instance);
    if (!isInstancePaid) return;

    const res = await fetchDialogs(instance.chat_key);
    if (!res) return;

    const availableDialogList = getAvailableDialogList(instance, res);

    setDialogs(prevValue =>
      update(prevValue, {[instance.id]: {$set: availableDialogList}})
    );

    const title = getInstanceLabel(instance);
    setNotification({title, text: t`The connection is ready to go.`});
  };

  return handleConnected;
};

export default useHandleConnected;
