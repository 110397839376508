import React from 'react';

import UserMessage, {
  MessageText
} from '../../components/user-message/user-message';
import {useAvitoMessageContext} from '../avito-message-context';

const Text = () => {
  const {message} = useAvitoMessageContext();

  return (
    <UserMessage>
      <MessageText>
        {'text' in message.content && message.content.text}
      </MessageText>
    </UserMessage>
  );
};

export default Text;
