import React from 'react';
import {useTranslation} from 'react-i18next';

import {Placeholder} from 'components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

import Instance from './instance/instance';
import {useEventsWs} from './hooks';

const InstanceList = () => {
  const {client} = useWorkspaceContext();
  const {t} = useTranslation();
  useEventsWs();

  const instances = Object.values(client.instances);

  return (
    <>
      {instances.length ? (
        <div className="instances">
          {instances.map(item => (
            <Instance instance={item} key={item.id} />
          ))}
        </div>
      ) : (
        <Placeholder
          title={t`There are no connections to display`}
          isMaxHeight
        />
      )}
    </>
  );
};

export default InstanceList;
