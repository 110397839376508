import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

const useGetInstances = () => {
  const {fetchChatInfo} = useRequest();

  const getInstances = async (client: AppEnv.WebAuth) => {
    const newInstances: AppEnv.Instances = {};

    const promises = Object.values(client.instances).map(async instance => {
      const chatInfo = await fetchChatInfo(instance.chat_key);
      if (!chatInfo) return;

      newInstances[instance.id] = {...instance, chatInfo};
    });

    await Promise.all(promises);
    return newInstances;
  };

  return getInstances;
};

export default useGetInstances;
