import {useDispatchContext} from 'pages/workspace/messenger/dispatch/dispatch-context';
import {useRequest} from 'common/hooks';
import {useTelegramMessageContext} from '../telegram-message-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

const useGetRemoteFile = () => {
  const {updateMessageBody} = useWorkspaceContext();
  const {instance} = useDispatchContext();
  const {message} = useTelegramMessageContext();

  const {fetchRemoteFile} = useRequest();

  const getRemoteFile = async (
    id: string | undefined,
    isUpdateMessageBody?: boolean
  ) => {
    if (!id) return null;

    const res = await fetchRemoteFile(instance.chat_key, id);
    if (!res?.path) return null;

    if (isUpdateMessageBody) updateMessageBody(message.id, res.path);
    return res.path;
  };

  return getRemoteFile;
};

export default useGetRemoteFile;
