import React, {useState, useEffect, forwardRef} from 'react';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import cn from 'classnames';
import moment from 'moment';

import {Preloader} from 'components';
import {useChatContext} from '../chat-context';
import {useScrollClassName} from 'common/hooks';
import {getDateDepth, getMessageTimestamp} from 'common/actions';

import Message from './message/message';
import SystemMessage from './message/components/system-message/system-message';
import {useGetMessageList} from './hooks';

const Convo = forwardRef<HTMLDivElement>((props, ref) => {
  const {dialog} = useChatContext();
  const [prevScrollHeight, setPrevScrollHeight] = useState(0);

  const {t} = useTranslation();
  const getMessageList = useGetMessageList();

  useEffect(() => {
    if (!ref || typeof ref != 'object') return;
    const {clientHeight, scrollHeight, scrollTop} = ref.current || {};

    if (dialog.messages && scrollHeight == prevScrollHeight) getMessageList();

    if (
      clientHeight == undefined ||
      scrollHeight == undefined ||
      scrollTop == undefined
    )
      return;

    if (scrollTop == 0)
      ref.current?.scrollTo({top: scrollHeight - prevScrollHeight});
    else if (scrollHeight - (scrollTop + clientHeight) < 512)
      ref.current?.scrollTo({top: scrollHeight, behavior: 'smooth'});

    setPrevScrollHeight(scrollHeight);
  }, [dialog.messages?.length]);

  useEffect(() => {
    if (!dialog.messages) getMessageList();
  }, []);

  const handleScroll = () => {
    if (!ref || typeof ref != 'object') return;

    const {scrollTop, clientHeight} = ref.current || {};
    if ((scrollTop || 0) < (clientHeight || 0)) getMessageList();
  };

  const scrollClassName = useScrollClassName();

  return (
    <div
      className={cn('chat__convo', {[scrollClassName]: scrollClassName})}
      ref={ref}
      onScroll={_.debounce(handleScroll)}
    >
      {dialog.messages ? (
        <div className="chat__convo-wrapper">
          {dialog.messages.length > 0 ? (
            Object.keys(dialog.messages).map(key => {
              const item = dialog.messages?.[parseInt(key)];
              if (!item) return;

              const timestamp = getMessageTimestamp(item);

              const datetime = timestamp
                ? moment.unix(timestamp).format('L')
                : undefined;

              const depth = timestamp ? getDateDepth(timestamp) : undefined;
              const previousMessage = dialog.messages?.[parseInt(key) - 1];

              const previousMessageTimestamp = previousMessage
                ? getMessageTimestamp(previousMessage)
                : undefined;

              const previousMessageDatetime = moment
                .unix(previousMessageTimestamp || 0)
                .format('L');

              return (
                <React.Fragment key={item.id}>
                  {datetime != previousMessageDatetime && (
                    <SystemMessage>
                      {depth == 'day'
                        ? t`Today`
                        : timestamp && moment.unix(timestamp).format('LL')}
                    </SystemMessage>
                  )}

                  <Message message={item} />
                </React.Fragment>
              );
            })
          ) : (
            <SystemMessage>{t`There are no messages here`}</SystemMessage>
          )}
        </div>
      ) : (
        <Preloader isMaxHeight />
      )}
    </div>
  );
});

Convo.displayName = 'Convo';
export default Convo;
