import React from 'react';
import {useTranslation} from 'react-i18next';

import MessagePreview from '../components/message-preview/message-preview';
import UserMessage, {
  MessageText
} from '../components/user-message/user-message';
import * as AppEnv from 'app-env';

import Audio from './audio/audio';
import Chat from './chat/chat';
import Document from './document/document';
import Image from './image/image';
import List from './list/list';
import Location from './location/location';
import MultiVcard from './multi-vcard/multi-vcard';
import Order from './order/order';
import PollCreation from './poll-creation/poll-creation';
import Product from './product/product';
import Vcard from './vcard/vcard';
import Video from './video/video';
import WhatsAppMessageContext from './whatsapp-message-context';

interface WhatsAppMessage {
  message: AppEnv.WhatsAppMessage;
}

const WhatsAppMessage = ({message}: WhatsAppMessage) => {
  const {t} = useTranslation();

  return (
    <WhatsAppMessageContext.Provider value={{message}}>
      {message.type == 'audio' || message.type == 'ptt' ? (
        <Audio />
      ) : message.type == 'buttons_response' ? (
        <Chat />
      ) : message.type == 'call_log' ? (
        <MessagePreview />
      ) : message.type == 'calls' ? (
        <MessagePreview />
      ) : message.type == 'chat' ? (
        <Chat />
      ) : message.type == 'ciphertext' ? (
        <MessagePreview />
      ) : message.type == 'document' ? (
        <Document />
      ) : message.type == 'e2e_notification' ? (
        <MessagePreview isSystemMessage />
      ) : message.type == 'gp2' ? (
        <MessagePreview isSystemMessage />
      ) : message.type == 'image' ? (
        <Image />
      ) : message.type == 'list' ? (
        <List />
      ) : message.type == 'location' ? (
        <Location />
      ) : message.type == 'multi_vcard' ? (
        <MultiVcard />
      ) : message.type == 'notification_template' ? (
        <MessagePreview isSystemMessage />
      ) : message.type == 'order' ? (
        <Order />
      ) : message.type == 'poll_creation' ? (
        <PollCreation />
      ) : message.type == 'product' ? (
        <Product />
      ) : message.type == 'protocol' ? (
        <MessagePreview isSystemMessage />
      ) : message.type == 'ptv' ? (
        <Video isCircle />
      ) : message.type == 'revoked' ? (
        <MessagePreview isLight />
      ) : message.type == 'sticker' ? (
        <Image />
      ) : message.type == 'vcard' ? (
        <Vcard />
      ) : message.type == 'video' ? (
        <Video />
      ) : (
        <UserMessage>
          <MessageText isLight>
            {t`The message is not supported on this version.`}
          </MessageText>
        </UserMessage>
      )}
    </WhatsAppMessageContext.Provider>
  );
};

export default WhatsAppMessage;
