import React from 'react';
import cn from 'classnames';

import {Text} from 'components';
import {useGetFolderDialogList} from 'common/hooks';
import {useDispatchContext} from '../../../dispatch-context';
import {getUnreadDialogList} from 'common/actions';
import * as AppEnv from 'app-env';

export interface Option {
  label: string;
  value: AppEnv.Folder;
}

interface Folder {
  option: Option;
}

const Folder = ({option}: Folder) => {
  const {dialogs, folder, instance, setFolder} = useDispatchContext();
  const getFolderDialogList = useGetFolderDialogList();

  const dialogList = dialogs[instance.id];

  const folderDialogList =
    dialogList && option.value
      ? getFolderDialogList(dialogList, option.value)
      : undefined;

  const unreadCount =
    option.value != 'archive' && folderDialogList
      ? getUnreadDialogList(folderDialogList).length
      : undefined;

  return (
    <div
      className={cn('folders__item', {
        folders__item_active: option.value == folder
      })}
      onClick={() => setFolder(option.value)}
    >
      <Text size={12} isNoWrap>
        {option.label}
      </Text>

      {!!unreadCount && (
        <span className="folders__count">
          <Text color="white" size={12} fontWeight={600} textAlign="center">
            {unreadCount}
          </Text>
        </span>
      )}
    </div>
  );
};

export default Folder;
