import React from 'react';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {Label as InstanceLabel} from '../../components';
import {
  Button,
  Popup,
  PopupFooter,
  PopupWrapper,
  SaveButton,
  Wrapper
} from 'components';
import {useAppContext} from 'app-context';
import {useInstanceContext} from '../../instance-context';
import {useGetInstanceLabel, useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import Label from './label/label';
import Webhook from './webhook/webhook';

interface Values {
  label: string;
  webhook: string;
}

interface Editor {
  isEditorActive: boolean;
  setIsEditorActive: AppEnv.SetState<boolean>;
}

const Editor = ({isEditorActive, setIsEditorActive}: Editor) => {
  const {setNotification} = useAppContext();
  const {client, setClient} = useWorkspaceContext();
  const {instance} = useInstanceContext();

  const {postChatUpdate} = useRequest();
  const {t} = useTranslation();
  const getInstanceLabel = useGetInstanceLabel();

  const handleSubmit = async (values: Values) => {
    const res = await postChatUpdate(instance.chat_key, values);
    if (!res) return true;

    setClient(prevValue =>
      update(prevValue, {
        instances: {
          [instance.id]: {
            chatInfo: {webhook: {$set: res.webhook || ''}},
            label: {$set: res.label || ''}
          }
        }
      })
    );

    setIsEditorActive(false);

    const title = getInstanceLabel(instance);
    setNotification({title, text: t`The settings have been saved.`});

    return true;
  };

  const {chatInfo} = instance;
  const {integration} = client;

  const initialValues: Values = {
    label: instance.label,
    webhook: chatInfo?.webhook || ''
  };

  return (
    <Popup
      width={408}
      isActive={isEditorActive}
      onClick={() => setIsEditorActive(false)}
    >
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <PopupWrapper>
            <InstanceLabel style={{marginBottom: 12}} />

            <Wrapper direction="column" gap={16}>
              <Label />
              {integration.widget_code && <Webhook />}
            </Wrapper>
          </PopupWrapper>

          <PopupFooter>
            <Wrapper gap={12}>
              <SaveButton>{t`Save`}</SaveButton>

              <Button color="fade" onClick={() => setIsEditorActive(false)}>
                {t`Cancel`}
              </Button>
            </Wrapper>
          </PopupFooter>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Editor;
