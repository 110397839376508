import React from 'react';

import UserMessage, {
  MessageAudio
} from '../../components/user-message/user-message';

import {useAppContext} from 'app-context';
import {useConvert} from 'common/hooks';
import {useTelegramMessageContext} from '../telegram-message-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import useGetRemoteFile from '../common/use-get-remote-file';
import {normalizeWaveform} from 'common/actions';

const VoiceNote = () => {
  const {isCanPlayOgg} = useAppContext();
  const {client, updateMessageBody} = useWorkspaceContext();
  const {message} = useTelegramMessageContext();

  const convert = useConvert();
  const getRemoteFile = useGetRemoteFile();

  const handleClick = async () => {
    const {integration} = client;

    const remotePath = await getRemoteFile(
      message.content.voiceNote?.voice.remote.id
    );

    if (!remotePath) return;
    else if (isCanPlayOgg) return updateMessageBody(message.id, remotePath);

    const res = await convert(remotePath, 'mp3', integration.domain);
    if (!res) return;

    updateMessageBody(message.id, res.url);
  };

  const waveform = message.content.voiceNote?.waveform
    ? normalizeWaveform(message.content.voiceNote?.waveform)
    : Array(100).fill(1);

  return (
    <UserMessage>
      <MessageAudio
        duration={message.content.voiceNote?.duration}
        message={message}
        waveform={waveform}
        onClick={handleClick}
      />
    </UserMessage>
  );
};

export default VoiceNote;
