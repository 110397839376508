import React from 'react';
import {Anchor, Icons} from 'components';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import * as AppEnv from 'app-env';

interface Header {
  messengerReferer: string | undefined;
  setIsSidebarActive: AppEnv.SetState<boolean>;
}

const Header = ({messengerReferer, setIsSidebarActive}: Header) => {
  const {t} = useTranslation();
  const location = useLocation();

  return (
    <div className="sidebar__header">
      {location.pathname.startsWith('/messenger') ? (
        <Anchor onClick={() => setIsSidebarActive(false)}>
          <Icons.ArrowLeft />
          <span>{t`Back to chats`}</span>
        </Anchor>
      ) : (
        <Link to={messengerReferer || '/messenger'}>
          <Anchor onClick={() => setIsSidebarActive(false)}>
            <Icons.ArrowLeft />
            <span>{t`Back to chats`}</span>
          </Anchor>
        </Link>
      )}
    </div>
  );
};

export default Header;
