import React, {useEffect, useRef, useState} from 'react';

import UserMessage, {
  MessageAudio
} from '../../components/user-message/user-message';
import {useAppContext} from 'app-context';
import {useConvert, useRequest} from 'common/hooks';
import {useDispatchContext} from 'pages/workspace/messenger/dispatch/dispatch-context';
import {useWhatsAppMessageContext} from '../whatsapp-message-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import useMedia from '../use-media';

const Audio = () => {
  const {isCanPlayOgg} = useAppContext();
  const {client, updateMessageBody} = useWorkspaceContext();
  const {instance} = useDispatchContext();
  const {message} = useWhatsAppMessageContext();

  const [src, setSrc] = useState<string>();

  const {fetchDownloadMedia} = useRequest();
  const convert = useConvert();
  const ref = useRef<HTMLDivElement>(null);
  useMedia(ref);

  useEffect(() => {
    if (isCanPlayOgg) return;

    setSrc(message.body);
    updateMessageBody(message.id, '');
  }, []);

  const handleClick = async () => {
    const {integration} = client;
    if (!message.mediaKey) return;

    const media = await fetchDownloadMedia(
      instance.chat_key,
      message.id,
      message.mediaKey
    );

    const url = !Array.isArray(media) && media?.url ? media.url : src;
    if (!url) return;

    const converted = await convert(url, 'mp3', integration.domain);
    if (converted) updateMessageBody(message.id, converted.url);
  };

  const {_data} = message;

  const waveform =
    _data.waveform && Array.isArray(_data.waveform)
      ? _data.waveform
      : undefined;

  return (
    <UserMessage>
      <MessageAudio
        duration={parseInt(message?.duration || '0')}
        message={message}
        waveform={waveform}
        onClick={handleClick}
      />
    </UserMessage>
  );
};

export default Audio;
