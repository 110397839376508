import React from 'react';
import cn from 'classnames';

interface DangerouslySetInnerHTML {
  __html: string;
}

export interface TextParams {
  children?: React.ReactNode;
  className?: string;
  color?: 'black' | 'green' | 'light' | 'optional' | 'secondary' | 'white';
  dangerouslySetInnerHTML?: DangerouslySetInnerHTML;
  fontWeight?: 500 | 600;
  isNoWrap?: boolean;
  isSpan?: boolean;
  size?: 12 | 16 | 20 | 24 | 72 | 144;
  style?: React.CSSProperties;
  textAlign?: 'center' | 'right';
}

const Text = ({
  children,
  className,
  color,
  dangerouslySetInnerHTML,
  fontWeight,
  isNoWrap,
  isSpan,
  size,
  style,
  textAlign
}: TextParams) => {
  const content = dangerouslySetInnerHTML
    ? {dangerouslySetInnerHTML}
    : {children};

  const params = {
    ...content,
    className: cn('text', {
      [`${className}`]: className,
      [`text_color-${color}`]: color,
      [`text_size-${size}`]: size,
      text_nowrap: isNoWrap
    }),
    style: {...style, fontWeight, textAlign}
  };

  return isSpan ? <span {...params} /> : <p {...params} />;
};

export default Text;
