import React from 'react';
import cn from 'classnames';

import {Text} from 'components';

interface SystemMessage {
  children: React.ReactNode;
  isAlt?: boolean;
}

const SystemMessage = ({children, isAlt}: SystemMessage) => (
  <div className="system-message">
    <div
      className={cn('system-message__bubble', {
        'system-message__bubble_alt': isAlt
      })}
    >
      <Text color="white" textAlign={!isAlt ? 'center' : undefined}>
        {children}
      </Text>
    </div>
  </div>
);

export default SystemMessage;
