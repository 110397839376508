import React from 'react';

import {Text} from 'components';
import {useDispatchContext} from 'pages/workspace/messenger/dispatch/dispatch-context';
import {useDialogContext} from '../dialog-context';
import {getAvitoDialogName, getDialogName} from 'common/actions';

const Name = () => {
  const {instance} = useDispatchContext();
  const {dialog} = useDialogContext();

  const name =
    dialog.version == 'avito'
      ? getAvitoDialogName(instance, dialog)
      : getDialogName(instance, dialog);

  return (
    <Text className="dialog__name" color="black" fontWeight={600} isNoWrap>
      {name}
    </Text>
  );
};

export default Name;
