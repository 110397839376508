import React from 'react';

import {Text as CoreText} from 'components';
import UserMessage, {
  MessageText
} from '../../components/user-message/user-message';
import {useTelegramMessageContext} from '../telegram-message-context';
import {getIsEmoji} from 'common/actions';

const Text = () => {
  const {message} = useTelegramMessageContext();

  const text = message.body || message.caption;
  const isEmoji = getIsEmoji(text);

  return (
    <UserMessage>
      <MessageText>
        {isEmoji ? <CoreText size={72}>{text}</CoreText> : text}
      </MessageText>
    </UserMessage>
  );
};

export default Text;
