import React from 'react';

import {useConnectionsMainContext} from '../../connections-main-context';
import * as AppEnv from 'app-env';

import Connector from './connector/connector';
import InstanceBody from './instance-body/instance-body';
import InstanceContext from './instance-context';
import InstanceHeader from './instance-header/instance-header';
import InstanceMenu from './instance-menu/instance-menu';

interface Instance {
  instance: AppEnv.Instance;
}

const Instance = ({instance}: Instance) => {
  const {connectorId} = useConnectionsMainContext();

  return (
    <InstanceContext.Provider value={{instance}}>
      <div className="instance">
        <InstanceHeader />
        <InstanceBody />
        <InstanceMenu />
        {instance.id == connectorId && <Connector />}
      </div>
    </InstanceContext.Provider>
  );
};

export default Instance;
