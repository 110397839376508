import React from 'react';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import {putInLocalStorage, removeFromLocalStorage} from 'whatcrm-core';

import {
  Button,
  Popup,
  PopupFooter,
  PopupWrapper,
  SaveButton,
  Title,
  Wrapper
} from 'components';
import {useChatContext} from '../../../chat-context';
import * as AppEnv from 'app-env';

import Wallpaper from './wallpaper/wallpaper';

interface Values {
  wallpaper: string | undefined;
}

interface Wallpapers {
  isActive: boolean;
  setIsActive: AppEnv.SetState<boolean>;
}

const Wallpapers = ({isActive, setIsActive}: Wallpapers) => {
  const {wallpaperId, setWallpaperId} = useChatContext();
  const {t} = useTranslation();

  const handleSubmit = (values: Values) => {
    setWallpaperId(values.wallpaper);

    if (values.wallpaper) putInLocalStorage('wallpaper', values.wallpaper);
    else removeFromLocalStorage('wallpaper');

    setIsActive(false);
  };

  const initialValues: Values = {wallpaper: wallpaperId};

  return (
    <Popup width={408} isActive={isActive} onClick={() => setIsActive(false)}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <PopupWrapper>
            <Title.H3 style={{marginBottom: 16}}>{t`Chat wallpapers`}</Title.H3>
            <Wallpaper />
          </PopupWrapper>

          <PopupFooter>
            <Wrapper gap={12}>
              <SaveButton>{t`Save`}</SaveButton>

              <Button color="fade" onClick={() => setIsActive(false)}>
                {t`Cancel`}
              </Button>
            </Wrapper>
          </PopupFooter>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Wallpapers;
