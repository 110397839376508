import React, {useEffect} from 'react';

import {useIsVisible} from 'common/hooks';
import {useTelegramMessageContext} from '../telegram-message-context';

import useGetRemoteFile from './use-get-remote-file';

const useRemoteFile = (
  ref: React.RefObject<HTMLElement>,
  id: string | undefined
) => {
  const {message} = useTelegramMessageContext();

  const getRemoteFile = useGetRemoteFile();
  const isVisible = useIsVisible(ref);

  useEffect(() => {
    if (
      isVisible &&
      (!message.body || message.body.startsWith('data:image/png;base64,'))
    )
      getRemoteFile(id, true);
  }, [isVisible]);
};

export default useRemoteFile;
