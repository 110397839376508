import React from 'react';

import TelegramMessagePhoto from '../components/telegram-message-photo/telegram-message-photo';
import UserMessage, {
  MessageProduct
} from '../../components/user-message/user-message';
import {useTelegramMessageContext} from '../telegram-message-context';

const Invoice = () => {
  const {message} = useTelegramMessageContext();

  return (
    <UserMessage>
      <MessageProduct
        currency={message.content.currency}
        description={message.content.description?.text}
        price={(message.content.totalAmount || 0) / 100}
        title={message.content.title}
      >
        <TelegramMessagePhoto />
      </MessageProduct>
    </UserMessage>
  );
};

export default Invoice;
