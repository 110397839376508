import React, {useEffect} from 'react';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';

import {Body} from 'components';
import * as AppEnv from 'app-env';

import Aside from './aside/aside';
import Window from './window/window';

const Widget = () => {
  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${t`Web widget`} - Whatcrm Web`;
  }, []);

  const initialValues: AppEnv.Widget = {
    bottom_mobile: null,
    bottom: null,
    callback_button_text: '',
    callback_field_type: 'linear',
    callback_is_message: false,
    callback_type: 'horizontal',
    chat_message_type: 'minimalism',
    chat_type: 'max',
    icon: 'faq-chat',
    is_callback: false,
    is_chat: false,
    is_pulse: false,
    right_mobile: null,
    right: null,
    socials: [
      {src: '', version: 'telegram'},
      {src: '', version: 'whatsapp'}
    ],
    theme: 'light',
    type: 'radial'
  };

  return (
    <Body>
      <div className="widget">
        <Formik initialValues={initialValues} onSubmit={() => {}}>
          <Form>
            <Window />
            <Aside />
          </Form>
        </Formik>
      </div>
    </Body>
  );
};

export default Widget;
