import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import useGetAvailableDialogList from './use-get-available-dialog-list/use-get-available-dialog-list';

const useGetDialogs = (
  client: AppEnv.WebAuth,
  activeInstances: AppEnv.Instance[]
) => {
  const {fetchDialogs} = useRequest();
  const getAvailableDialogList = useGetAvailableDialogList(client);

  const getDialogs = async () => {
    const dialogs: AppEnv.Dialogs = {};

    const promises = activeInstances.map(async instance => {
      const res = await fetchDialogs(instance.chat_key);
      if (!res) return;

      const availableDialogList = getAvailableDialogList(instance, res);
      dialogs[instance.id] = availableDialogList;
    });

    await Promise.all(promises);
    return dialogs;
  };

  return getDialogs;
};

export default useGetDialogs;
