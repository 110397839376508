import React, {useState, useEffect} from 'react';
import _ from 'lodash';

import {MessageText} from '../user-message';
import InnerMessage from '../inner-message/inner-message';
import {useChatContext} from 'pages/workspace/messenger/dispatch/chat-provider/chat/chat-context';
import {useGetMessagePreview} from 'common/hooks';
import {useMessageContext} from '../../../message-context';

const QuotedMessage = () => {
  const {convoRef} = useChatContext();
  const {message} = useMessageContext();

  const [target, setTarget] = useState<HTMLDivElement>();
  const getMessagePreview = useGetMessagePreview();

  const quotedMsg =
    'quotedMsg' in message
      ? message.quotedMsg
      : 'quote' in message
      ? message.quote
      : undefined;

  useEffect(() => {
    if (!quotedMsg) return;

    const el: HTMLDivElement | null = document.querySelector(
      `[data-id="${quotedMsg.id}" ]`
    );

    if (!el) return;
    setTarget(el);
  }, []);

  const handleScroll = _.debounce(() => {
    convoRef.current?.removeEventListener('scroll', handleScroll);
    if (!target) return;

    target.classList.add('message_active');
    setTimeout(() => target.classList.remove('message_active'), 150);
  }, 150);

  const handleClick = () => {
    if (!target) return;

    convoRef.current?.addEventListener('scroll', handleScroll);
    target.scrollIntoView({behavior: 'smooth', block: 'center'});
  };

  const messagePreview = getMessagePreview(quotedMsg);

  return (
    <>
      {messagePreview && (
        <InnerMessage isQuote onClick={target ? handleClick : undefined}>
          <MessageText>{messagePreview}</MessageText>
        </InnerMessage>
      )}
    </>
  );
};

export default QuotedMessage;
