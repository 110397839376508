import React, {useState} from 'react';
import {formatPhoneNumber, useCopyToClipboard} from 'whatcrm-core';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {Confirm} from 'components';
import {Item} from '../components';
import {useAppContext} from 'app-context';
import {useChatContext} from '../../../chat-context';
import {useDispatchContext} from 'pages/workspace/messenger/dispatch/dispatch-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import {getDialogPhoneNumber} from 'common/actions';

const List = () => {
  const {setNotification} = useAppContext();
  const {client, setClient} = useWorkspaceContext();
  const {instance} = useDispatchContext();
  const {dialog, telegramUser} = useChatContext();

  const [isConfirmationActive, setIsConfirmationActive] = useState(false);

  const {postBlacklistCreate} = useRequest();
  const {t} = useTranslation();
  const copyToClipboard = useCopyToClipboard();
  const navigate = useNavigate();

  const handleClick = async (str: string) => {
    const res = await copyToClipboard(str);
    setNotification({title: res.message});
  };

  const blockUser = async () => {
    if (dialog.version != 'whatcrm') return true;
    const {integration} = client;

    const res = await postBlacklistCreate(integration.id, dialog.id.user);
    if (!res) return true;

    setClient(prevValue => update(prevValue, {blacklist: {$push: [res]}}));
    setNotification({title: t`The user has been blocked`});
    navigate(`/${instance.id}`);

    return true;
  };

  const handleConfirm = (res: boolean) =>
    res ? blockUser() : setIsConfirmationActive(false);

  const isBlockable = dialog.version == 'whatcrm' && !dialog.isGroup;
  const isBulletin = dialog.version == 'avito' && dialog.context.value.url;
  const isLocation = dialog.version == 'avito' && dialog.context.value.location;

  const phoneNumber = getDialogPhoneNumber(dialog, telegramUser);

  return (
    <>
      <div className="profile__list">
        {isBulletin && (
          <Item
            href={dialog.context.value.url}
            label={t`bulletin`}
            type="copy"
            value={`${dialog.context.value.title} • ${dialog.context.value.price_string}`}
          />
        )}

        {isLocation && (
          <Item
            label={t`location`}
            type="copy"
            value={dialog.context.value.location?.title || ''}
            onClick={() =>
              handleClick(dialog.context.value.location?.title || '')
            }
          />
        )}

        {phoneNumber && (
          <Item
            label={t`phone number`}
            type="copy"
            value={formatPhoneNumber(phoneNumber)}
            onClick={() => handleClick(phoneNumber)}
          />
        )}

        {telegramUser?.usernames && (
          <Item
            label={t`username`}
            type="copy"
            value={`@${telegramUser.usernames.editableUsername}`}
            onClick={() =>
              handleClick(telegramUser.usernames?.editableUsername || '')
            }
          />
        )}

        {isBlockable && (
          <Item
            type="ban"
            value={t`Block`}
            onClick={() => setIsConfirmationActive(true)}
          />
        )}
      </div>

      <Confirm
        title={t`Are you sure you want to block this user?`}
        isActive={isConfirmationActive}
        onClick={handleConfirm}
      />
    </>
  );
};

export default List;
