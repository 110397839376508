import React from 'react';
import {useTranslation} from 'react-i18next';

import {Media} from 'components';
import UserMessage, {
  MessageText
} from '../../components/user-message/user-message';
import {useAvitoMessageContext} from '../avito-message-context';

const Image = () => {
  const {message} = useAvitoMessageContext();
  const {t} = useTranslation();

  const key = message.content.image
    ? Object.keys(message.content.image.sizes)[0]
    : undefined;

  const [width, height] = key ? key.split('x') : [];
  const src = key ? message.content.image?.sizes[key] : undefined;

  return (
    <UserMessage>
      {src ? (
        <Media
          height={parseInt(height)}
          src={src}
          width={parseInt(width)}
          isBackground
        />
      ) : (
        <MessageText isLight>
          {t`The message is not supported on this version.`}
        </MessageText>
      )}
    </UserMessage>
  );
};

export default Image;
