import React, {forwardRef} from 'react';
import {useTranslation} from 'react-i18next';

import {Media} from 'components';
import {MessageText} from '../user-message';

interface MessageProduct {
  children?: React.ReactNode;
  currency: string | undefined;
  description: string | undefined;
  height?: number;
  href?: string;
  price: number;
  retailerId?: string;
  src?: string;
  title: string | undefined;
  width?: number | undefined;
}

const MessageProduct = forwardRef<HTMLDivElement, MessageProduct>(
  (
    {
      children,
      currency,
      description,
      height,
      href,
      price,
      retailerId,
      src,
      title,
      width
    },
    ref
  ) => {
    const {i18n} = useTranslation();

    return (
      <div
        className="message-product"
        ref={ref}
        style={{width: width ? (width > 720 ? width : width / 2) : undefined}}
      >
        {src && <Media height={height} src={src.toLowerCase()} width={width} />}
        {children}

        <div className="message-product__wrapper">
          <div>
            {title && <MessageText>{title}</MessageText>}

            <MessageText isBold isLight>
              {price.toLocaleString(i18n.resolvedLanguage, {
                currency: currency,
                style: 'currency'
              })}
            </MessageText>
          </div>

          <div>
            {description && <MessageText>{description}</MessageText>}

            {href && (
              <MessageText>
                <a
                  className="message__link"
                  href={href}
                  rel="noreferrer"
                  target="_blank"
                >
                  {href}
                </a>
              </MessageText>
            )}

            {retailerId && <MessageText>{retailerId}</MessageText>}
          </div>
        </div>
      </div>
    );
  }
);

MessageProduct.displayName = 'MessageProduct';
export default MessageProduct;
