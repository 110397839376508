import React, {RefObject, useEffect, useRef} from 'react';
import {Picker} from 'emoji-mart';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';
import data from '@emoji-mart/data';

import {Button, Icons} from 'components';

interface Value {
  native: string;
}

interface Emoji {
  textRef: RefObject<HTMLTextAreaElement>;
}

const Emoji = ({textRef}: Emoji) => {
  const [field, , helpers] = useField<string>('text');
  const {i18n: i18next} = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const handleEmojiSelect = ({native}: Value) => {
    if (!textRef.current) return;
    textRef.current.focus();

    const selectionStart = textRef.current.selectionStart;
    const selectionEnd = textRef.current.selectionEnd;

    const text =
      field.value.substring(0, selectionStart) +
      native +
      field.value.substring(selectionEnd);

    helpers.setValue(text);
    textRef.current.selectionEnd = selectionEnd + 2;
  };

  useEffect(() => {
    // eslint-disable-next-line
    const i18n = require(
      `@emoji-mart/data/i18n/${
        i18next.resolvedLanguage?.slice(0, 2) || 'en'
      }.json`
    );

    new Picker({
      data,
      i18n,
      ref,
      onEmojiSelect: handleEmojiSelect
    });
  }, [field.value]);

  return (
    <div className="emoji-picker">
      <Button color="transparent" onClick={() => {}}>
        <Icons.Smiley />
      </Button>

      <div className="emoji-picker__mart" ref={ref} />
    </div>
  );
};

export default Emoji;
