import React from 'react';

import {Text} from 'components';
import {useDispatchContext} from 'pages/workspace/messenger/dispatch/dispatch-context';
import {useDialogContext} from '../dialog-context';
import {useGetMessagePreview} from 'common/hooks';
import {getIsMessageLimited, getStyledText} from 'common/actions';

const Message = () => {
  const {instance} = useDispatchContext();
  const {dialog} = useDialogContext();

  const getMessagePreview = useGetMessagePreview();

  const getLastMessage = () => {
    if (dialog.version == 'avito' || dialog.version == 'chat')
      return dialog.last_message;
    else if (dialog.version == 'telegram' || dialog.version == 'whatcrm')
      return dialog.lastMessage;
  };

  const lastMessage = getLastMessage();
  const messagePreview = getMessagePreview(lastMessage);

  const isMessageLimited = lastMessage
    ? getIsMessageLimited(instance, lastMessage)
    : undefined;

  return (
    <>
      {!isMessageLimited && (
        <Text
          className="dialog__message"
          color="black"
          dangerouslySetInnerHTML={{
            __html: getStyledText(messagePreview || '', instance.version)
          }}
          size={12}
          isNoWrap
        />
      )}
    </>
  );
};

export default Message;
