import {useFormikContext} from 'formik';
import {useMemo} from 'react';
import jwtEncode from 'jwt-encode';

const useScript = () => {
  const {values} = useFormikContext();
  const jwt = useMemo(() => jwtEncode(values, ''), [values]);

  return `<script src="http://localhost:8000/chat/${jwt}.js"></script>`;
};

export default useScript;
