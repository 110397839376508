import React, {useRef, useState} from 'react';
import {findPosition, useOnClickOutside, useOnKeyDown} from 'whatcrm-core';
import cn from 'classnames';

import {Icons} from 'components';

interface Option<T> {
  img: string;
  value: T;
}

interface SelectAlt<T> {
  onChange: (arg: T) => void;
  options: Option<T>[];
  value: T;
}

const SelectAlt = <T,>({onChange, options, value}: SelectAlt<T>) => {
  const [isActive, setIsActive] = useState(false);
  const [position, setPosition] = useState<React.CSSProperties>();

  const dropdownRef = useRef<HTMLDivElement>(null);
  const fieldRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(fieldRef, {onClick: () => setIsActive(false)});

  useOnKeyDown(
    e => {
      if (e.code == 'Escape') setIsActive(false);
    },
    [isActive]
  );

  const handleClick = () => {
    const dropdownRect = dropdownRef.current?.getBoundingClientRect();
    const fieldRect = fieldRef.current?.getBoundingClientRect();

    if (!dropdownRect || !fieldRect) return;

    const res = findPosition(
      {height: dropdownRect.height, width: dropdownRect.width},
      {
        triggerPosition: {
          x: fieldRect.left - 8,
          y: fieldRect.top + fieldRect.height + 8
        }
      }
    );

    setPosition(res);
    setIsActive(true);
  };

  const selected = options.find(option => option.value == value);

  return (
    <div className={cn('select-alt', {'select-alt_active': isActive})}>
      <div className="select-alt__field" ref={fieldRef} onClick={handleClick}>
        <div className="select-alt__label">
          <img src={selected?.img} />
        </div>

        <Icons.CaretDown color="green" size={20} />
      </div>

      <div className="select-alt__overlay">
        <div
          className="select-alt__dropdown"
          ref={dropdownRef}
          style={position}
        >
          {options.map((option, i) => (
            <div
              className={cn('select-alt__option', {
                'select-alt__option_active': option.value == value
              })}
              key={i}
              onClick={() => onChange(option.value)}
            >
              <div className="select-alt__label">
                <img src={option.img} />
              </div>

              <div className="select-alt__checkbox">
                <Icons.Check color="white" size={16} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectAlt;
