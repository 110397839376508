import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

const useInitialVersion = (): AppEnv.InstanceVersion | undefined => {
  const {client} = useWorkspaceContext();
  const {integration} = client;

  if (['AVITO'].includes(integration.type)) return 'avito';
  else if (['TELEGRAM', 'TELPHGRAM', 'TGMONDAY'].includes(integration.type))
    return 'telegram';
  else if (['AMO', 'B24', 'MONDAY', 'PIPEDRIVE'].includes(integration.type))
    return 'whatcrm';
};

export {useInitialVersion};
