import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface WorkspaceContext {
  activeInstances: AppEnv.Instance[];
  client: AppEnv.WebAuth;
  setClient: AppEnv.SetState<AppEnv.WebAuth | null | undefined>;
  setTariffs: AppEnv.SetState<AppEnv.Tariffs | null | undefined>;
  tariffs: AppEnv.Tariffs | null | undefined;
  updateMessageBody: (
    messageId: number | string,
    body: string,
    editDate?: number
  ) => void;
}

const WorkspaceContext = React.createContext<WorkspaceContext>(
  {} as WorkspaceContext
);

export const useWorkspaceContext = () => useContext(WorkspaceContext);
export default WorkspaceContext;
