import React from 'react';
import ReactAvatar from 'react-avatar';
import cn from 'classnames';

import {useDispatchContext} from '../../dispatch-context';

interface ProfileImage {
  id?: number | string | null;
  isBig?: boolean;
  name?: string | null;
  src?: string | null;
  tag?: 'online' | 'version';
}

const colors = [
  '#c2d2ff',
  '#abdcff',
  '#edc8f7',
  '#ffcfbf',
  '#93edbf',
  '#c2d2ff',
  '#abdcff',
  '#edc8f7',
  '#ffcfbf',
  '#93edbf'
];

const getColor = (str: number | string) => {
  let normalizedStr = str;

  if (typeof normalizedStr == 'string' && !parseInt(normalizedStr)) {
    const encoder = new TextEncoder();
    normalizedStr = encoder.encode('Hello').join('');
  }

  const index = parseInt(normalizedStr.toString().slice(-1));
  return colors[index];
};

const ProfileImage = ({id, isBig, name, src, tag}: ProfileImage) => {
  const {instance} = useDispatchContext();
  const color = getColor(id || 0);

  const htmlSrc =
    src ||
    (name?.[0] == '+' ? '/static/img/profile-image/user.svg' : undefined);

  return (
    <div
      className={cn('profile-image', {
        'profile-image_big': isBig,
        'profile-image_online': tag == 'online',
        [`profile-image_${instance.version}`]: tag == 'version'
      })}
      style={{background: color}}
    >
      <ReactAvatar
        className="profile-image__avatar"
        color={color}
        maxInitials={2}
        name={name || undefined}
        size={isBig ? '112' : '40'}
        src={htmlSrc}
      />
    </div>
  );
};

export {default as useProfileImage} from './hooks/use-profile-image';
export default ProfileImage;
