import React from 'react';
import {Icons} from 'components';

import Date from './date/date';
import PaymentStatus from './payment-status/payment-status';
import {useInstancePaymentStatus} from './hooks';

const InstanceHeader = () => {
  const instancePaymentStatus = useInstancePaymentStatus();

  return (
    <div className="instance__header">
      {instancePaymentStatus ? (
        <>
          <PaymentStatus />
          <Date />
        </>
      ) : (
        <Icons.Loading size={24} />
      )}
    </div>
  );
};

export default InstanceHeader;
