import {useEffect, useRef, useState} from 'react';
import {IMessageEvent, w3cwebsocket} from 'websocket';

import {useReconnectWs} from 'common/hooks';
import {useWebSocketProviderContext} from '../../web-socket-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import {getMessageEventData} from 'common/actions';

import {useHandleNewMessage} from './hooks';
import {MessageEventData} from './use-avito-ws-env';

const useAvitoWs = () => {
  const {activeInstances} = useWorkspaceContext();
  const {dialogs} = useWebSocketProviderContext();

  const [channels, setChannels] = useState<string[]>([]);

  const handleNewMessage = useHandleNewMessage();
  const webSocket = useRef<w3cwebsocket | null>(null);

  const handleMessage = (hook: IMessageEvent) => {
    const messageEventData = getMessageEventData<MessageEventData>(hook);
    if (messageEventData?.type == 'message') handleNewMessage(messageEventData);
  };

  const updateChannels = async () => {
    const add: string[] = [];
    const ch = [...channels];

    activeInstances.forEach(instance => {
      if (ch.includes(instance.instanceId)) return;
      else if (instance.version != 'avito') return;

      add.push(instance.instanceId);
      ch.push(instance.instanceId);
    });

    setChannels(ch);

    if (!add.length) return;
    webSocket.current?.send(JSON.stringify({ch: add, cmd: 'subscribe'}));
  };

  const connectWs = async () => {
    try {
      webSocket.current = new w3cwebsocket('wss://avito.whatcrm.net/v1/ws');
      webSocket.current.binaryType = 'arraybuffer';
      webSocket.current.onclose = reconnectWs;
      webSocket.current.onmessage = handleMessage;
      webSocket.current.onopen = updateChannels;
    } catch {
      //
    }
  };

  useEffect(() => {
    if (webSocket.current) updateChannels();
  }, [activeInstances.length]);

  useEffect(() => {
    if (webSocket.current) webSocket.current.onmessage = handleMessage;
  }, [dialogs]);

  useEffect(() => {
    if (webSocket.current) webSocket.current.onopen = updateChannels;
  }, [channels]);

  useEffect(() => {
    connectWs();
  }, []);

  const reconnectWs = useReconnectWs(connectWs);
};

export default useAvitoWs;
