import React from 'react';
import {useTranslation} from 'react-i18next';

import UserMessage, {
  MessageText
} from '../../components/user-message/user-message';
import {useTelegramMessageContext} from '../telegram-message-context';
import {Wrapper} from 'components';

const Venue = () => {
  const {message} = useTelegramMessageContext();
  const {i18n} = useTranslation();

  return (
    <UserMessage>
      <Wrapper direction="column" gap={8}>
        <iframe
          className="message-location"
          src={`https://maps.google.com/maps?hl=${i18n.resolvedLanguage}&output=embed&q=${message.content.venue?.location.latitude},${message.content.venue?.location.longitude}`}
        />

        <div>
          <MessageText>{message.content.venue?.title}</MessageText>

          <MessageText isLight isSmall>
            {message.content.venue?.address}
          </MessageText>
        </div>
      </Wrapper>
    </UserMessage>
  );
};

export default Venue;
