import React, {useRef} from 'react';

import {Media, Wrapper} from 'components';
import UserMessage, {
  MessageText
} from '../../components/user-message/user-message';
import {useWhatsAppMessageContext} from '../whatsapp-message-context';
import useMedia from '../use-media';

const Image = () => {
  const {message} = useWhatsAppMessageContext();

  const ref = useRef<HTMLDivElement>(null);
  useMedia(ref);

  let height = message._data.height;
  let width = message._data.width;

  if (message.type == 'sticker') {
    height = 288;
    width = 288;
  }

  return (
    <UserMessage>
      <Wrapper direction="column" gap={8}>
        <Media
          height={height}
          ref={ref}
          src={message.body.toLowerCase()}
          width={width}
          noSubstrate={message.type == 'sticker'}
          noZoom={message.type == 'sticker'}
        />

        {message._data.caption && (
          <MessageText>{message._data.caption}</MessageText>
        )}
      </Wrapper>
    </UserMessage>
  );
};

export default Image;
