import {useEffect, useState} from 'react';

import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';

import useCreateDialogs from './use-create-dialogs/use-create-dialogs';
import useGetDialogs from './use-get-dialogs/use-get-dialogs';

type UseDialogs = [
  AppEnv.Dialogs | undefined,
  AppEnv.SetState<AppEnv.Dialogs | undefined>
];

const useDialogs = (
  client: AppEnv.WebAuth,
  activeInstances: AppEnv.Instance[]
): UseDialogs => {
  const {isIframe} = useAppContext();
  const [dialogs, setDialogs] = useState<AppEnv.Dialogs>();

  const createDialogs = useCreateDialogs(activeInstances);
  const getDialogs = useGetDialogs(client, activeInstances);

  const getDialogList = async () => {
    const newDialogs = await (isIframe ? createDialogs() : getDialogs());
    setDialogs(newDialogs);
  };

  useEffect(() => {
    getDialogList();
  }, []);

  return [dialogs, setDialogs];
};

export default useDialogs;
