import React from 'react';

import {Icons, Text} from 'components';
import {useDialogContext} from '../dialog-context';
import {getIsDialogPinned, getIsDialogUnread} from 'common/actions';

const Badges = () => {
  const {dialog} = useDialogContext();

  const isPinned = getIsDialogPinned(dialog);
  const isUnread = getIsDialogUnread(dialog);

  return (
    <div className="dialog__badges">
      {isPinned && <Icons.Pin color="gray" />}

      {isUnread && (
        <div className="dialog__count">
          <Text color="white" size={12} fontWeight={600} textAlign="center">
            {(dialog.version == 'telegram' || dialog.version == 'whatcrm') &&
              dialog.unreadCount > 0 &&
              dialog.unreadCount}
          </Text>
        </div>
      )}
    </div>
  );
};

export default Badges;
