import React from 'react';

import UserMessage, {
  MessageAudio
} from '../../components/user-message/user-message';

import {useTelegramMessageContext} from '../telegram-message-context';
import useGetRemoteFile from '../common/use-get-remote-file';

const Audio = () => {
  const {message} = useTelegramMessageContext();
  const getRemoteFile = useGetRemoteFile();

  return (
    <UserMessage>
      <MessageAudio
        duration={message.content.audio?.duration}
        fileName={message.content.audio?.fileName}
        message={message}
        size={message.content.audio?.audio.size}
        onClick={() =>
          getRemoteFile(message.content.audio?.audio.remote.id, true)
        }
      />
    </UserMessage>
  );
};

export default Audio;
