import update from 'immutability-helper';

import {useAppContext} from 'app-context';
import {
  useGetIsDialogAvailable,
  useNotification,
  useRequest
} from 'common/hooks';
import {useWebSocketProviderContext} from '../../web-socket-context';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import {getDialogId, getIsMessageOutgoing} from 'common/actions';
import * as AppEnv from 'app-env';

const getDialogLastMessage = (dialog: AppEnv.Dialog) => {
  if (dialog.version == 'telegram' || dialog.version == 'whatcrm')
    return dialog.lastMessage;
};

const getIsLastMessage = (dialog: AppEnv.Dialog, message: AppEnv.Message) => {
  if (!('senderId' in message) && !('from' in message)) return false;

  const lastMessage = getDialogLastMessage(dialog);
  if (!lastMessage) return false;

  const lastMessageId =
    'senderId' in lastMessage ? lastMessage.id : lastMessage.id._serialized;

  return lastMessageId == message.id;
};

const getIsNewMessage = (dialog: AppEnv.Dialog, message: AppEnv.Message) => {
  const lastMessage = getDialogLastMessage(dialog);
  if (!lastMessage) return false;

  if ('senderId' in lastMessage && 'senderId' in message)
    return lastMessage.date < message.timestamp;
  else if ('_data' in lastMessage && '_data' in message)
    return lastMessage.timestamp < message.timestamp;

  return false;
};

const getOriginalId = (serializedId: string): AppEnv.WhatsAppMessageId => {
  const [fromMe, remote, id] = serializedId.split('_');

  const originalId = {
    _serialized: serializedId,
    fromMe: fromMe == 'true' ? true : false,
    id,
    remote
  };

  return originalId;
};

const useHandleMessages = () => {
  const {isIframe} = useAppContext();
  const {client} = useWorkspaceContext();
  const {dialogs, setDialogs} = useWebSocketProviderContext();

  const {fetchDialog} = useRequest();
  const getIsDialogAvailable = useGetIsDialogAvailable(client);
  const notification = useNotification();

  const getNewTelegramDialog = async (
    instance: AppEnv.Instance,
    chatId: number | string
  ) => {
    const res = await fetchDialog(instance.chat_key, chatId);
    return res;
  };

  const getNewWhatsAppDialog = async (message: AppEnv.Message) => {
    if (!('_data' in message)) return null;

    const lastMessage: AppEnv.WhatsAppLastMessage = {
      ...message,
      id: {_serialized: message.id, remote: message.chatId}
    };

    const splitChatId = message.chatId.split('@');
    const server = splitChatId[1] as 'c.us' | 'g.us';

    const res: AppEnv.WhatsAppDialog = {
      id: {_serialized: message.chatId, server: server, user: splitChatId[0]},
      isGroup: server == 'g.us',
      lastMessage: lastMessage,
      muteExpiration: 0,
      name: message.chatName,
      pinned: false,
      timestamp: message.timestamp,
      unreadCount: message.fromMe || message.type == 'e2e_notification' ? 0 : 1,
      version: 'whatcrm'
    };

    return res;
  };

  const handleNewDialog = async (
    instance: AppEnv.Instance,
    chatId: number | string,
    message: AppEnv.Message
  ) => {
    const res = await (instance.version == 'telegram'
      ? getNewTelegramDialog(instance, chatId)
      : getNewWhatsAppDialog(message));

    if (!res) return;

    const isDialogAvailable = getIsDialogAvailable(instance, res);
    if (!isDialogAvailable) return;

    setDialogs(prevValue => {
      const dialogIndex = prevValue?.[instance.id]?.findIndex(
        item => getDialogId(item, true) == getDialogId(res, true)
      );

      if (typeof dialogIndex == 'number' && dialogIndex > 0) return prevValue;
      return update(prevValue, {[instance.id]: {$push: [res]}});
    });

    notification(instance, res, message);
  };

  const updateUnreadCount = async (
    instance: AppEnv.Instance,
    dialogIndex: number,
    isOutgoing: boolean
  ) =>
    setDialogs(prevValue =>
      update(prevValue, {
        [instance.id]: {
          [dialogIndex]: {unreadCount: target => (isOutgoing ? 0 : target + 1)}
        }
      })
    );

  const updateMessage = (
    instance: AppEnv.Instance,
    dialogIndex: number,
    message: AppEnv.Message
  ) => {
    const dialog = dialogs[instance.id]?.[dialogIndex];
    if (!dialog) return;

    const messageIndex = dialog.messages?.findIndex(
      item =>
        item.id == message.id ||
        ('senderId' in item && item.oldId == message.id)
    );

    if (messageIndex == undefined || messageIndex > -1) {
      setDialogs(prevValue =>
        update(prevValue, {
          [instance.id]: {
            [dialogIndex]: {
              messages: {[messageIndex || 0]: {$set: message as any}} // eslint-disable-line
            }
          }
        })
      );

      return;
    }

    setDialogs(prevValue =>
      update(prevValue, {
        [instance.id]: {[dialogIndex]: {messages: {$push: [message as any]}}} // eslint-disable-line
      })
    );
  };

  const updateLastMessage = (
    instance: AppEnv.Instance,
    dialogIndex: number,
    message: AppEnv.Message
  ) => {
    const originalId =
      typeof message.id == 'string' ? getOriginalId(message.id) : message.id;

    setDialogs(prevValue =>
      update(prevValue, {
        [instance.id]: {
          [dialogIndex]: {
            lastMessage: {$set: {...message, id: originalId} as any} // eslint-disable-line
          }
        }
      })
    );
  };

  const handleNewMessage = (
    instance: AppEnv.Instance,
    dialogIndex: number,
    message: AppEnv.Message
  ) => {
    const dialog = dialogs[instance.id]?.[dialogIndex];

    if (
      !dialog ||
      (dialog.version != 'telegram' && dialog.version != 'whatcrm')
    ) {
      return;
    }

    if (dialog.messages) updateMessage(instance, dialogIndex, message);

    const isLastMessage = getIsLastMessage(dialog, message);
    const isNewMessage = getIsNewMessage(dialog, message);

    if (isNewMessage) {
      const isOutgoing = getIsMessageOutgoing(dialog, message);
      updateUnreadCount(instance, dialogIndex, isOutgoing);
      if (!isOutgoing) notification(instance, dialog, message);
    }

    if (!dialog.lastMessage || isLastMessage || isNewMessage)
      updateLastMessage(instance, dialogIndex, message);
  };

  const handleMessages = (
    instance: AppEnv.Instance,
    messages: AppEnv.Message[]
  ) => {
    const [message] = messages;
    if (!('senderId' in message) && !('_data' in message)) return;

    const dialogIndex = dialogs[instance.id]?.findIndex(
      item => getDialogId(item, true) == message.chatId
    );

    if (dialogIndex == undefined || dialogIndex < 0) {
      if (!isIframe) handleNewDialog(instance, message.chatId, message);
      return;
    }

    handleNewMessage(instance, dialogIndex, message);
  };

  return handleMessages;
};

export default useHandleMessages;
