import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {SelectAlt, Title} from 'components';

const Type = () => {
  const [field, , helpers] = useField<string>('icon');
  const {t} = useTranslation();

  const options = [
    {img: '/static/widget/icons/faq-chat.svg', value: 'faq-chat'},
    {img: '/static/widget/icons/help-center.svg', value: 'help-center'},
    {img: '/static/widget/icons/live-chat.svg', value: 'live-chat'},
    {img: '/static/widget/icons/support-24.svg', value: 'support-24'},
    {
      img: '/static/widget/icons/virtual-assistant.svg',
      value: 'virtual-assistant'
    }
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Icon`}</Title.H3>
      <SelectAlt {...field} options={options} onChange={helpers.setValue} />
    </div>
  );
};

export default Type;
