import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';

import {Placeholder} from 'components';
import Auth from 'pages/auth/auth';
import Record from 'pages/record/record';
import Workspace from 'pages/workspace/workspace';
import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';

interface Router {
  client: AppEnv.WebAuth | null;
  setClient: AppEnv.SetState<AppEnv.WebAuth | null | undefined>;
}

const Router = ({client, setClient}: Router) => {
  const {isIframe} = useAppContext();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/auth"
          element={<Auth client={client} setClient={setClient} />}
        />

        <Route path="/record" element={<Record />} />

        <Route
          path="*"
          element={
            client ? (
              <Workspace client={client} setClient={setClient} />
            ) : isIframe ? (
              <Placeholder isMaxHeight />
            ) : (
              <Navigate to="/auth" />
            )
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
