import React from 'react';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {
  Button,
  PopupFooter,
  PopupWrapper,
  SaveButton,
  Text,
  Title,
  Wrapper
} from 'components';
import {Label} from '../../components';
import {useInstanceContext} from '../../instance-context';
import {useRequest} from 'common/hooks';

import Password from './password/password';

interface Values {
  password: string;
}

const WaitPassword = () => {
  const {instance} = useInstanceContext();

  const {
    fetchInstanceCheckAuthenticationPassword,
    fetchInstanceRequestQrCodeAuthentication
  } = useRequest();

  const {t} = useTranslation();

  const handleClick = () =>
    fetchInstanceRequestQrCodeAuthentication(instance.chat_key);

  const handleSubmit = async (values: Values) => {
    await fetchInstanceCheckAuthenticationPassword(
      instance.chat_key,
      values.password
    );

    return true;
  };

  const initialValues: Values = {password: ''};

  const validationSchema = yup
    .object()
    .shape({password: yup.string().trim().required()});

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      <Form>
        <PopupWrapper>
          <Label style={{marginBottom: 12}} />

          <Title.H4 style={{marginBottom: 4}}>
            {t`Enter your password`}
          </Title.H4>

          <Text style={{marginBottom: 16}}>
            {t`Your account is protected with an additional password.`}
          </Text>

          <Password />
        </PopupWrapper>

        <PopupFooter>
          <Wrapper gap={12}>
            <SaveButton>{t`Continue`}</SaveButton>
            <Button color="fade" onClick={handleClick}>{t`Cancel`}</Button>
          </Wrapper>
        </PopupFooter>
      </Form>
    </Formik>
  );
};

export default WaitPassword;
