import React, {useRef} from 'react';

import {Media, Wrapper} from 'components';

import UserMessage, {
  MessageText
} from '../../components/user-message/user-message';

import {useTelegramMessageContext} from '../telegram-message-context';
import useRemoteFile from '../common/use-remote-file';

interface Video {
  name: 'animation' | 'video' | 'videoNote';
}

const Video = ({name}: Video) => {
  const {message} = useTelegramMessageContext();
  const ref = useRef<HTMLDivElement>(null);

  const content = message.content[name];

  const getRemoteFile = () => {
    if (!content) return;
    else if ('animation' in content) return content.animation;
    else if ('video' in content) return content.video;
  };

  const remoteFile = getRemoteFile();
  useRemoteFile(ref, remoteFile?.remote.id);

  const getSize = () => {
    if (name == 'videoNote') return [360, 360];
    else if (content && 'height' in content)
      return [content.height, content.width];

    return [];
  };

  const [height, width] = getSize();

  const minithumbnail = content?.minithumbnail
    ? `data:image/png;base64,${content.minithumbnail.data}`
    : undefined;

  return (
    <UserMessage>
      <Wrapper direction="column" gap={8}>
        <Media
          controls={name != 'animation'}
          height={height}
          minithumbnail={minithumbnail}
          ref={ref}
          src={message.body.toLowerCase()}
          type={message.body ? 'video' : undefined}
          width={width}
          isAutoPlay={name == 'animation'}
          isCircle={name == 'videoNote'}
        />

        {message.caption && <MessageText>{message.caption}</MessageText>}
      </Wrapper>
    </UserMessage>
  );
};

export default Video;
