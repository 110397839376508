import React, {useContext} from 'react';
import {CoreEnv} from 'whatcrm-core';

import * as AppEnv from 'app-env';

interface AppContext {
  chatIdList: string[] | undefined;
  isCanPlayOgg: boolean;
  isIframe: boolean;
  phoneNumberList: string[] | undefined;
  setNotification: AppEnv.SetState<AppEnv.Notification | undefined>;
  setTheme: AppEnv.SetState<CoreEnv.Theme>;
  theme: CoreEnv.Theme;
  toneRef: React.RefObject<HTMLAudioElement>;
  usernameList: string[] | undefined;
}

const AppContext = React.createContext<AppContext>({} as AppContext);

export const useAppContext = () => useContext(AppContext);
export default AppContext;
