import React, {useState, useRef} from 'react';
import {findPosition, useOnClickOutside, useOnKeyDown} from 'whatcrm-core';
import cn from 'classnames';

import {Icons, Text} from 'components';
import {useScrollClassName} from 'common/hooks';

interface Position {
  left: number;
  top: number;
}

interface Option<T> {
  comment?: string;
  icon?: React.ReactNode;
  label: string;
  value: T;
}

interface Select<T> {
  isAlt?: boolean;
  isFilter?: boolean;
  isValid?: boolean;
  onChange: (arg: T) => void;
  options: Option<T>[];
  placeholder?: string;
  title?: string;
  value: T;
}

const Select = <T,>({
  isAlt,
  isFilter,
  isValid = true,
  onChange,
  options,
  placeholder,
  title,
  value
}: Select<T>) => {
  const [isActive, setIsActive] = useState(false);
  const [position, setPosition] = useState<Position>();

  const targetRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(targetRef, {onClick: () => setIsActive(false)});

  useOnKeyDown(
    e => {
      if (e.code == 'Escape') setIsActive(false);
    },
    [isActive]
  );

  const handleActive = () => {
    const targetRect = targetRef.current?.getBoundingClientRect();
    const triggerRect = triggerRef.current?.getBoundingClientRect();

    if (!targetRect || !triggerRect) return;

    const res = findPosition(
      {height: targetRect.height, width: targetRect.width},
      {
        triggerPosition: {
          x: triggerRect.left - (isAlt ? 16 : 3),
          y: triggerRect.top + triggerRect.height + (isAlt ? 0 : 8)
        }
      }
    );

    setPosition(res);
    setIsActive(true);
  };

  const handleClick = (optionValue: T) => {
    onChange(optionValue);
    setIsActive(false);
  };

  const {label} = options.find(item => item.value == value) || {};
  const scrollClassName = useScrollClassName();
  const triggerRect = triggerRef.current?.getBoundingClientRect();
  const isHtmlAlt = isAlt || isFilter;

  return (
    <div
      className={cn('select', {
        select_active: isActive,
        select_alt: isHtmlAlt,
        select_filter: isFilter
      })}
    >
      <div
        className={cn('select__field', {
          select__field_error: !isValid
        })}
        ref={triggerRef}
        onClick={handleActive}
      >
        {isFilter ? (
          <Icons.Filter className="icon_filter" />
        ) : (
          <>
            <div style={{overflow: 'hidden'}}>
              {title && (
                <Text color="secondary" size={label ? 12 : 16} fontWeight={500}>
                  {title}
                </Text>
              )}

              <Text size={16} fontWeight={!isAlt ? 500 : undefined} isNoWrap>
                {label || placeholder}
              </Text>
            </div>

            <Icons.MinArrowDown />
          </>
        )}
      </div>

      <div className="select__dropdown">
        <div
          className={cn('select__dropdown-wrapper', {
            [scrollClassName]: scrollClassName
          })}
          ref={targetRef}
          style={{
            ...position,
            width: (triggerRect?.width || 0) + (isAlt ? 32 : 6)
          }}
        >
          {options.map((item, i) => (
            <div
              key={i}
              className={cn('select__option', {
                select__option_active: item.value == value
              })}
              onClick={() => handleClick(item.value)}
            >
              <div
                className={cn('select__group', {select__group_gap: item.icon})}
              >
                <Text className="select__label" size={16} isNoWrap>
                  {item.label}
                </Text>

                {item.comment && (
                  <div className="select__comment">
                    {item.icon}
                    <Text size={12}>{item.comment}</Text>
                  </div>
                )}
              </div>

              <Icons.Check size={20} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Select;
