import React, {useState} from 'react';

import {Button, Wrapper} from 'components';
import UserMessage, {
  MessageText
} from '../../components/user-message/user-message';
import {useWhatsAppMessageContext} from '../whatsapp-message-context';

import Listing from './listing/listing';

const List = () => {
  const {message} = useWhatsAppMessageContext();
  const [isListActive, setIsListActive] = useState(false);

  return (
    <>
      <UserMessage>
        <Wrapper direction="column" gap={8}>
          <MessageText>{message._data.list?.description}</MessageText>

          <Button color="white" isSmall onClick={() => setIsListActive(true)}>
            {message._data.list?.buttonText}
          </Button>
        </Wrapper>
      </UserMessage>

      <Listing isListActive={isListActive} setIsListActive={setIsListActive} />
    </>
  );
};

export default List;
