import React from 'react';
import {useTranslation} from 'react-i18next';

import MessagePreview from '../components/message-preview/message-preview';
import UserMessage, {
  MessageText
} from '../components/user-message/user-message';
import * as AppEnv from 'app-env';

import AnimatedEmoji from './animated-emoji/animated-emoji';
import Audio from './audio/audio';
import Contact from './contact/contact';
import Document from './document/document';
import Invoice from './invoice/invoice';
import Location from './location/location';
import Photo from './photo/photo';
import Poll from './poll/poll';
import Sticker from './sticker/sticker';
import TelegramMessageContext from './telegram-message-context';
import Text from './text/text';
import Venue from './venue/venue';
import Video from './video/video';
import VoiceNote from './voice-note/voice-note';

interface TelegramMessage {
  message: AppEnv.TelegramMessage;
}

const TelegramMessage = ({message}: TelegramMessage) => {
  const {t} = useTranslation();

  return (
    <TelegramMessageContext.Provider value={{message}}>
      {message.type == 'animatedEmoji' ? (
        <AnimatedEmoji />
      ) : message.type == 'animation' ? (
        <Video name="animation" />
      ) : message.type == 'audio' ? (
        <Audio />
      ) : message.type == 'basicGroupChatCreate' ? (
        <MessagePreview isSystemMessage />
      ) : message.type == 'call' ? (
        <MessagePreview />
      ) : message.type == 'chatAddMembers' ? (
        <MessagePreview isSystemMessage />
      ) : message.type == 'chatChangePhoto' ? (
        <MessagePreview isSystemMessage />
      ) : message.type == 'chatChangeTitle' ? (
        <MessagePreview isSystemMessage />
      ) : message.type == 'chatDeleteMember' ? (
        <MessagePreview isSystemMessage />
      ) : message.type == 'chatJoinByLink' ? (
        <MessagePreview isSystemMessage />
      ) : message.type == 'chatUpgradeFrom' ? (
        <MessagePreview isSystemMessage />
      ) : message.type == 'contact' ? (
        <Contact />
      ) : message.type == 'contactRegistered' ? (
        <MessagePreview isSystemMessage />
      ) : message.type == 'document' ? (
        <Document />
      ) : message.type == 'forumTopicCreated' ? (
        <MessagePreview isSystemMessage />
      ) : message.type == 'forumTopicEdited' ? (
        <MessagePreview isSystemMessage />
      ) : message.type == 'invoice' ? (
        <Invoice />
      ) : message.type == 'location' ? (
        <Location />
      ) : message.type == 'photo' ? (
        <Photo />
      ) : message.type == 'poll' ? (
        <Poll />
      ) : message.type == 'premiumGiveaway' ? (
        <MessagePreview isSystemMessage />
      ) : message.type == 'premiumGiveawayCreated' ? (
        <MessagePreview isSystemMessage />
      ) : message.type == 'sticker' ? (
        <Sticker />
      ) : message.type == 'supergroupChatCreate' ? (
        <MessagePreview isSystemMessage />
      ) : message.type == 'text' ? (
        <Text />
      ) : message.type == 'venue' ? (
        <Venue />
      ) : message.type == 'video' ? (
        <Video name="video" />
      ) : message.type == 'videoChatEnded' ? (
        <MessagePreview isSystemMessage />
      ) : message.type == 'videoChatStarted' ? (
        <MessagePreview isSystemMessage />
      ) : message.type == 'videoNote' ? (
        <Video name="videoNote" />
      ) : message.type == 'voiceNote' ? (
        <VoiceNote />
      ) : (
        <UserMessage>
          <MessageText isLight>
            {t`The message is not supported on this version.`}
          </MessageText>
        </UserMessage>
      )}
    </TelegramMessageContext.Provider>
  );
};

export default TelegramMessage;
