import React, {useState, useRef} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {LongPressReactEvents, useLongPress} from 'use-long-press';
import cn from 'classnames';

import ProfileImage, {
  useProfileImage
} from 'pages/workspace/messenger/dispatch/components/profile-image/profile-image';
import {useDispatchContext} from 'pages/workspace/messenger/dispatch/dispatch-context';
import {
  getAvitoProductImage,
  getDialogId,
  getDialogName,
  getDialogProfileImage,
  getIsDialogUnread
} from 'common/actions';
import * as AppEnv from 'app-env';

import Badges from './badges/badges';
import ContextMenuProvider from './context-menu-provider/context-menu-provider';
import Message from './message/message';
import Metadata from './metadata/metadata';
import Name from './name/name';
import DialogContext from './dialog-context';

interface TriggerPosition {
  x: number;
  y: number;
}

interface Dialog {
  dialog: AppEnv.Dialog;
}

const Dialog = ({dialog}: Dialog) => {
  const {instance} = useDispatchContext();

  const [triggerPosition, setTriggerPosition] = useState<TriggerPosition>();
  const [isContextMenuActive, setIsContextMenuActive] = useState(false);

  const {dialogId} = useParams();

  const bindLongPress = useLongPress(e => handleContextMenu(e), {
    cancelOnMovement: true
  });

  const dialogRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  useProfileImage(dialog, dialogRef);

  const getIsActive = () => {
    if (!dialogId) return false;

    if (dialog.version == 'avito' || dialog.version == 'telegram')
      return dialog.id == dialogId;
    else if (dialog.version == 'whatcrm') return dialog.id.user == dialogId;

    return false;
  };

  const isActive = getIsActive();

  const handleClick: React.MouseEventHandler<HTMLDivElement> = () => {
    setIsContextMenuActive(false);

    const id = getDialogId(dialog);
    const href = `/messenger/${instance.id}/${id}`;
    navigate(href);
  };

  const handleContextMenu = (e: MouseEvent | LongPressReactEvents<Element>) => {
    e.preventDefault();

    if (dialog.version == 'avito' && !getIsDialogUnread(dialog)) return;
    else if ('pageX' in e) setTriggerPosition({x: e.pageX, y: e.pageY});

    setIsContextMenuActive(true);
  };

  const src =
    dialog.version == 'avito'
      ? getAvitoProductImage(dialog)
      : getDialogProfileImage(instance, dialog);

  return (
    <DialogContext.Provider value={{dialog}}>
      <div>
        <div
          {...bindLongPress()}
          className={cn('dialog', {dialog_active: isActive})}
          ref={dialogRef}
          onClick={handleClick}
          onContextMenu={handleContextMenu}
        >
          <ProfileImage
            id={getDialogId(dialog)}
            name={getDialogName(instance, dialog)}
            src={src}
            tag="version"
          />

          <div className="dialog__wrapper">
            <div className="dialog__top">
              <Name />
              <Metadata />
            </div>

            <div className="dialog__bottom">
              <Message />
              <Badges />
            </div>
          </div>
        </div>

        <ContextMenuProvider
          dialogRef={dialogRef}
          triggerPosition={triggerPosition}
          isContextMenuActive={isContextMenuActive}
          setIsContextMenuActive={setIsContextMenuActive}
        />
      </div>
    </DialogContext.Provider>
  );
};

export default Dialog;
