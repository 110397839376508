import React from 'react';

import {AudioPlayer, AudioPlayerProps, Wrapper} from 'components';
import {MessageText} from '../user-message';
import * as AppEnv from 'app-env';

interface MessageAudio extends Omit<AudioPlayerProps, 'src'> {
  message: AppEnv.TelegramMessage | AppEnv.WhatsAppMessage;
}

const MessageAudio = (props: MessageAudio) => {
  const {message} = props;

  return (
    <Wrapper direction="column" gap={8}>
      <AudioPlayer {...props} src={message.body} />
      {message.caption && <MessageText>{message.caption}</MessageText>}
    </Wrapper>
  );
};

export default MessageAudio;
