import React from 'react';
import {formatPhoneNumber} from 'whatcrm-core';
import emojiRegex from 'emoji-regex';
import moment from 'moment';
import {IMessageEvent} from 'websocket';

import {statuses} from 'common/constants';
import * as AppEnv from 'app-env';

const downloadFile = (href: string, download: string, isBlank?: boolean) => {
  const a = document.createElement('a');

  a.download = download;
  a.href = href;
  if (isBlank) a.target = '_blank';

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const getAvitoDialogName = (
  instance: AppEnv.Instance,
  dialog: AppEnv.AvitoDialog
) => {
  const dialogName = getDialogName(instance, dialog);

  return [
    dialogName,
    dialog.context.value.title,
    dialog.context.value.price_string
  ].join(' • ');
};

const getAvitoProductImage = (dialog: AppEnv.AvitoDialog) => {
  const key: string | undefined = Object.keys(
    dialog.context.value.images?.main || []
  )[0];

  return dialog.context.value.images?.main[key] || null;
};

const getBlob = async (path: string) => {
  const res = await fetch(path.toLowerCase())
    .then(res => res.blob())
    .catch(() => null);

  if (!res || res.type == 'application/xml') return null;

  const objectURL = URL.createObjectURL(res);
  return objectURL;
};

const getChatDialogName = (dialog: AppEnv.ChatDialog) => {
  const {guest} = dialog;
  return [guest.name, guest.surname].join(' ');
};

const getDateDepth = (timestamp: number) => {
  const datetime = moment.unix(timestamp);

  const date = new Date();
  const now = moment(date);
  const midnight = moment(date.setHours(0, 0, 0, 0));

  const depth =
    datetime.format('L') == now.format('L')
      ? 'day'
      : moment.duration(midnight.diff(datetime)).asDays() <= 6
        ? 'week'
        : datetime.format('yyyy') == now.format('yyyy')
          ? 'year'
          : 'default';

  return depth;
};

const getDialogId = (dialog: AppEnv.Dialog, isSerialized?: boolean) => {
  if (
    dialog.version == 'avito' ||
    dialog.version == 'chat' ||
    dialog.version == 'telegram'
  )
    return dialog.id;
  else if (dialog.version == 'whatcrm')
    return isSerialized ? dialog.id._serialized : dialog.id.user;

  return null;
};

const getDialogLastMessage = (dialog: AppEnv.Dialog) => {
  if (dialog.version == 'avito' || dialog.version == 'chat')
    return dialog.last_message;
  else if (dialog.version == 'telegram' || dialog.version == 'whatcrm')
    return dialog.lastMessage;
};

const getDialogName = (instance: AppEnv.Instance, dialog: AppEnv.Dialog) => {
  let name: string | undefined = undefined;

  if (dialog.version == 'avito') {
    const user = dialog.users.find(item => item.id != instance.me?.id);
    name = user?.name;
  } else if (dialog.version == 'chat') {
    return getChatDialogName(dialog);
  } else if (dialog.version == 'telegram') {
    name = dialog.name;
  } else if (dialog.version == 'whatcrm') {
    const {lastMessage} = dialog;
    const {_data} = lastMessage || {};

    const isNotifyName =
      _data?.notifyName && !dialog.isGroup && !lastMessage?.fromMe;

    name = isNotifyName ? _data.notifyName : dialog.name;
  }

  if (!name) return null;
  return formatPhoneNumber(name);
};

const getDialogPath = (dialogs: AppEnv.Dialogs, id: number | string) => {
  let dialogIndex: number | undefined = undefined;
  let instanceId: number | undefined = undefined;

  for (const key in dialogs) {
    const ii = parseInt(key);
    const di = dialogs[ii]?.findIndex(item => getDialogId(item) == id);

    if (di != undefined && di > -1) {
      instanceId = ii;
      dialogIndex = di;
      break;
    }
  }

  return {dialogIndex, instanceId};
};

const getDialogPhoneNumber = (
  dialog: AppEnv.Dialog,
  telegramUser: AppEnv.TelegramUser | null | undefined
) => {
  if (telegramUser) {
    return telegramUser.phoneNumber;
  } else if (dialog.version == 'whatcrm') {
    if (!dialog.isGroup) return dialog.id.user;
  }
};

const getDialogProfileImage = (
  instance: AppEnv.Instance,
  dialog: AppEnv.Dialog
) => {
  if (dialog.version == 'avito') {
    const user = dialog.users.find(item => item.id != instance.me?.id);
    return user?.public_user_profile.avatar.default;
  } else if (dialog.version == 'telegram' || dialog.version == 'whatcrm') {
    return dialog.profileImage;
  }
};

const getDisplaySize = (
  width: number | undefined,
  height: number | undefined
): React.CSSProperties => {
  const base = 640;

  if (!width || !height)
    return {aspectRatio: '4 / 3', height: (base / 2 / 4) * 3};

  const aspectRatio = `${width} / ${height}`;
  const max = base * 2;

  const size =
    height > width
      ? {height: height < max ? height / 2 : base, width: 'max-content'}
      : {width: width < max ? width / 2 : base};

  return {...size, aspectRatio};
};

const getIsDialogGroup = (dialog: AppEnv.Dialog) =>
  dialog.version == 'telegram' || dialog.version == 'whatcrm'
    ? dialog.isGroup
    : false;

const getIsDialogPinned = (dialog: AppEnv.Dialog) => {
  if (dialog.version == 'telegram') return dialog.isPinned;
  else if (dialog.version == 'whatcrm') return dialog.pinned;
  return false;
};

const getIsDialogUnread = (dialog: AppEnv.Dialog) => {
  if (dialog.version == 'avito')
    return dialog.last_message.direction == 'in' && !dialog.last_message.read;
  else if (dialog.version == 'telegram')
    return !!dialog.unreadCount || dialog.isMarkedAsUnread;
  else if (dialog.version == 'whatcrm') return !!dialog.unreadCount;

  return false;
};

const testEmojiRegex =
  /^(?:(?:\p{RI}\p{RI}|\p{Emoji}(?:\p{Emoji_Modifier}|\u{FE0F}\u{20E3}?|[\u{E0020}-\u{E007E}]+\u{E007F})?(?:\u{200D}\p{Emoji}(?:\p{Emoji_Modifier}|\u{FE0F}\u{20E3}?|[\u{E0020}-\u{E007E}]+\u{E007F})?)*)|[\u{1f900}-\u{1f9ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}])+$/u;

const getIsEmoji = (str: string) => {
  const regex = emojiRegex();
  const emojis = str.match(regex) || [];

  const stringToTest = str.replace(/ /g, '');

  const isEmojiOnly =
    testEmojiRegex.test(stringToTest) && Number.isNaN(Number(stringToTest));

  return emojis.length == 1 && isEmojiOnly;
};

const getIsInstanceActive = (
  client: AppEnv.WebAuth,
  instance: AppEnv.Instance
) => {
  const isPaid = getIsInstancePaid(client, instance);
  const isWorking = statuses.includes(instance.status);

  return isPaid && isWorking;
};

const getIsInstancePaid = (
  client: AppEnv.WebAuth,
  instance: AppEnv.Instance
) => {
  const {chatInfo} = instance;
  const {integration} = client;
  const now = +new Date() / 1000;

  if (integration.is_premium) return true;
  else if (!chatInfo) return false;

  return chatInfo.date_subscription > now || chatInfo.date_trial > now;
};

const getIsInstanceWorking = (instance: AppEnv.Instance) =>
  statuses.includes(instance.status);

const getIsLimited = (instance: AppEnv.Instance) => {
  const isPremium = !!instance.chatInfo?.is_premium;
  const isStart = instance.chatInfo?.tariff_plane == 'Start';

  const isTrial = !!(
    instance.chatInfo?.date_trial &&
    instance.chatInfo.date_trial > +new Date() / 1000
  );

  return !(isPremium || isTrial || !isStart);
};

const getIsMessageLimited = (
  instance: AppEnv.Instance,
  message: AppEnv.AnyMessage
) => {
  const isLimited = getIsLimited(instance);
  if (!isLimited) return false;

  const isAvitoCall =
    'author_id' in message &&
    message.content.text?.startsWith(
      '[Системное сообщение] Пропущенный звонок'
    );

  const isTelegramCall = message.type == 'call';
  const isWhatsAppCall = message.type == 'call_log' || message.type == 'calls';

  return isAvitoCall || isTelegramCall || isWhatsAppCall;
};

const getIsMessageOutgoing = (
  dialog: AppEnv.Dialog,
  message: AppEnv.Message
) => {
  if ('author_id' in message) return message.direction == 'out';
  else if (dialog.version == 'chat' && 'sender_id' in message) {
    const {guest} = dialog;
    return guest.id != message.sender_id;
  } else if ('senderId' in message) return message.isOutgoing;
  else if ('from' in message) return message.fromMe;

  return false;
};

const getIsUnavailableCommunity = (
  instance: AppEnv.Instance,
  dialog: AppEnv.Dialog
) => {
  let isAdmin = false;

  const isCommunity =
    dialog.version == 'whatcrm' && !!dialog.groupMetadata?.announce;

  if (isCommunity) {
    isAdmin = !!dialog.groupMetadata?.participants.find(
      item =>
        item.id._serialized == `${instance.phone}@c.us` &&
        (item.isAdmin || item.isSuperAdmin)
    );
  }

  return !isAdmin && isCommunity;
};

const getIsNewMessageAvailable = (
  instance: AppEnv.Instance,
  dialog: AppEnv.Dialog
) => {
  const isChannel = dialog.version == 'telegram' && dialog.type.isChannel;
  const isUnavailableCommunity = getIsUnavailableCommunity(instance, dialog);
  const res = !isChannel && !isUnavailableCommunity;

  return res;
};

const getMessageEventData = <T>(messageEvent: IMessageEvent): T | undefined => {
  try {
    if (typeof messageEvent.data != 'string') return;
    return JSON.parse(messageEvent.data);
  } catch {
    //
  }
};

const getMessageTimestamp = (message: AppEnv.Message) => {
  if ('author_id' in message) return message.created;
  else if ('sender_id' in message) return moment(message.timestamp).unix();
  else if ('senderId' in message || 'from' in message) return message.timestamp;
};

const bytes = {
  mb16: 16777216,
  mb50: 52428800,
  mb64: 67108864,
  mb100: 104857600
};

const getSizeLimit = (instance: AppEnv.Instance, file: File) => {
  const isLimited = getIsLimited(instance);

  if (file.type.includes('image')) {
    return bytes.mb16;
  } else if (file.type.includes('video')) {
    return isLimited ? bytes.mb16 : bytes.mb64;
  }

  return isLimited ? bytes.mb50 : bytes.mb100;
};

const getStyledText = (
  text: string,
  version: AppEnv.InstanceVersion,
  isLinkStyled?: boolean
) => {
  let res = text;

  if (version == 'whatcrm') {
    res = text
      .replace(/_([^_]+)_/g, '<em>$1</em>')
      .replace(/\*([^*]+)\*/g, '<strong>$1</strong>')
      .replace(/```([^```]+)```/g, '<span class="monospace">$1</span>')
      .replace(/~([^~]+)~/g, '<span class="line-through">$1</span>');
  }

  if (isLinkStyled) {
    res = res.replace(/(https?:\/\/[^\s]+)/g, p1 => {
      const href = p1.replace(/(<([^>]+)>)/gi, '');
      return `<a class="message__link" href="${href}" rel="noreferrer" target="_blank">${href}</a>`;
    });
  }

  return res;
};

const getTime = (seconds: number) => {
  const [h, m, s] = moment
    .utc(new Date(0).setSeconds(seconds))
    .format('HH:mm:ss')
    .split(':');

  if (!h && !m && !s) return null;

  return `${parseInt(h) > 0 ? `${h}:` : ''}` + `${m}:` + s;
};

const getUnreadDialogList = (dialogList: AppEnv.Dialog[]) =>
  dialogList?.filter(
    item =>
      ('unreadCount' in item && item.unreadCount) ||
      ('isMarkedAsUnread' in item && item.isMarkedAsUnread)
  );

const normalizeWaveform = (data: string) => {
  const bytes = Array.from(atob(data)).map(x => x.charCodeAt(0) & 0xff);
  const waveform = [];
  const barsCount = Math.floor((bytes.length * 8) / 5);

  for (let i = 0; i < barsCount; i++) {
    const byteIndex = Math.floor((i * 5) / 8);
    const barPadding = (i * 5) % 8;

    const bits =
      bytes[byteIndex] |
      ((byteIndex + 1 < bytes.length ? bytes[byteIndex + 1] : 0) << 8);

    waveform.push((((bits >>> barPadding) & 0x1f) / 31.0) * 100);
  }

  for (let i = 0; i < 100 - barsCount; i++) {
    waveform.push(0);
  }

  return waveform;
};

const processTelegramDialog = (
  dialog: AppEnv.TelegramDialogShape
): AppEnv.TelegramDialog => ({
  ...dialog,
  isGroup: false,
  isPinned: false,
  name: dialog.title,
  timestamp: 0,
  version: 'telegram'
});

export {default as sortDialogs} from './sort-dialogs/sort-dialogs';

export {
  downloadFile,
  getAvitoDialogName,
  getAvitoProductImage,
  getBlob,
  getChatDialogName,
  getDateDepth,
  getDialogId,
  getDialogLastMessage,
  getDialogName,
  getDialogPath,
  getDialogPhoneNumber,
  getDialogProfileImage,
  getDisplaySize,
  getIsDialogGroup,
  getIsDialogPinned,
  getIsDialogUnread,
  getIsEmoji,
  getIsInstanceActive,
  getIsInstancePaid,
  getIsInstanceWorking,
  getIsLimited,
  getIsMessageLimited,
  getIsMessageOutgoing,
  getIsNewMessageAvailable,
  getMessageEventData,
  getMessageTimestamp,
  getSizeLimit,
  getStyledText,
  getTime,
  getUnreadDialogList,
  normalizeWaveform,
  processTelegramDialog
};
