import React from 'react';
import {Navigate} from 'react-router-dom';

import * as AppEnv from 'app-env';

import AuthContext from './auth-context';
import Main from './main/main';
import Side from './side/side';

interface Auth {
  client: AppEnv.WebAuth | null;
  setClient: AppEnv.SetState<AppEnv.WebAuth | null | undefined>;
}

const Auth = ({client, setClient}: Auth) => (
  <AuthContext.Provider value={{setClient}}>
    {client ? (
      <Navigate to="/" />
    ) : (
      <div className="auth">
        <div className="auth__wrapper">
          <Side />
          <Main />
        </div>
      </div>
    )}
  </AuthContext.Provider>
);

export default Auth;
