import React, {useState, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import {Button, ContextMenu, Icons} from 'components';
import {useChatContext} from '../../../chat-context';
import {useFooterContext} from '../../footer-context';
import {useDispatchContext} from 'pages/workspace/messenger/dispatch/dispatch-context';
import {getIsLimited} from 'common/actions';

import Attachs from './attachs/attachs';
import Poll from './poll/poll';
import Template from './template/template';

const PaperClip = () => {
  const {instance} = useDispatchContext();
  const {dialog, quotedMsg} = useChatContext();
  const {setIsPollActive, setTemplateState} = useFooterContext();

  const [isContextMenuActive, setIsContextMenuActive] = useState(false);

  const attachsRef = useRef<HTMLInputElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const {t} = useTranslation();

  const isLimited = getIsLimited(instance);

  const options = [
    {
      icon: <Icons.Note size={24} />,
      label: t`Template`,
      onClick: () => setTemplateState({isActive: true})
    },
    {
      icon: <Icons.ImageSquare size={24} />,
      label: t`File`,
      onClick: () => attachsRef.current?.click()
    }
  ];

  if (dialog.version == 'whatcrm' && !isLimited)
    options.unshift({
      icon: <Icons.ChartBarHorizontal size={24} />,
      label: t`Poll`,
      onClick: () => setIsPollActive(true)
    });

  return (
    <>
      <Button
        ref={triggerRef}
        color="transparent"
        isDisabled={quotedMsg?.isEditing}
        onClick={() => setIsContextMenuActive(prevValue => !prevValue)}
      >
        <Icons.PaperClip />
      </Button>

      <ContextMenu
        triggerRef={triggerRef}
        options={options}
        isActive={isContextMenuActive}
        isCustomPosition
        setIsActive={setIsContextMenuActive}
      />

      <Attachs ref={attachsRef} />
      <Poll />
      <Template />
    </>
  );
};

export default PaperClip;
